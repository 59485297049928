import React, { useState } from 'react'
import PropTypes from 'prop-types'
import httpService from '@services/httpService'
import { TOP_LIST_CRITERIA } from '@constants/tiktokRankings'

export const TikTokRankingsContext = React.createContext({})

const TikTokRankingsProvider = ({ children }) => {
  const defaultCriteriaObj = {
    list: [],
    isLoading: false,
    country: '',
    showLastMonth: true,
    showFullList: false,
    isError: false,
  }

  const [state, setState] = useState({
    [TOP_LIST_CRITERIA.views]: defaultCriteriaObj,
    [TOP_LIST_CRITERIA.followers]: defaultCriteriaObj,
    // add more criterias here
    isLoading: false,
    isInitDataPresent: false,
  })

  const updateState = updateObj => setState({ ...state, ...updateObj })

  const fetchRankList = async paramsObj => {
    try {
      return await httpService.fetchTopTTLists({ ...paramsObj })
    } catch (err) {
      return { userArray: [], isError: true }
    }
  }

  const getStartUpLists = () => {
    updateState({ isLoading: true })
    Promise.all([
      fetchRankList({ rankBy: TOP_LIST_CRITERIA.views }),
      fetchRankList({
        rankBy: TOP_LIST_CRITERIA.followers,
      }),
    ]).then(([views, followers]) => {
      updateState({
        [TOP_LIST_CRITERIA.views]: {
          ...state[TOP_LIST_CRITERIA.views],
          list: views.userArray,
          ...(views.isError && { isError: views.isError }),
        },
        [TOP_LIST_CRITERIA.followers]: {
          ...state[TOP_LIST_CRITERIA.followers],
          list: followers.userArray,
          ...(followers.isError && { isError: followers.isError }),
        },
        isLoading: false,
        isInitDataPresent: true,
      })
    })
  }

  const getRankList = ({
    rankName,
    country = '',
    useLastMonth = false,
  } = {}) => {
    ;(async () => {
      updateState({
        [rankName]: { ...state[rankName], isLoading: true, isError: false },
      })
      const data = await fetchRankList({
        rankBy: rankName,
        country,
        useLastMonth,
      })
      updateState({
        [rankName]: {
          ...state[rankName],
          list: data.userArray,
          country,
          showLastMonth: useLastMonth,
          isLoading: false,
        },
      })
    })()
  }

  const toggleRankFullList = rankName => {
    updateState({
      [rankName]: {
        ...state[rankName],
        showFullList: !state[rankName].showFullList,
      },
    })
  }

  const providerValue = {
    ...state,
    getStartUpLists,
    getRankList,
    toggleRankFullList,
  }

  return (
    <TikTokRankingsContext.Provider value={providerValue}>
      {children}
    </TikTokRankingsContext.Provider>
  )
}

TikTokRankingsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
}

TikTokRankingsProvider.defaultProps = {}

export default TikTokRankingsProvider
