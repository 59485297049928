import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import availableLangs from '@localization/availableLangs'

export const LocalizationContext = React.createContext({
  currentLang: '',
  labelStrings: {},
  pricingPlansStrings: {},
  languageOptions: [],
  countryOptions: [],
  changeCurrentLang: () => {},
})

const LocalizationProvider = ({ children, localizationObject, currentPath }) => {
  const { localizations, languageOptions, countryOptions } = localizationObject
  const getDeviceLang = () => {
    const deviceLang = localizations.getInterfaceLanguage()
    return deviceLang.includes(availableLangs.en) ? availableLangs.en : availableLangs.de
  }

  const getLang = () => {
    if (currentPath && currentPath.includes(`/${availableLangs.de}`)) {
      return availableLangs.de
    }
    return availableLangs.en
  }

  const defaultLang = getLang()
  localizations.setLanguage(defaultLang)
  const [currentLang, setCurrentLang] = useState(defaultLang)

  useEffect(() => {
    const currentPageLang = getLang()
    localizations.setLanguage(currentPageLang)
    setCurrentLang(currentPageLang)
  }, [currentPath])

  const changeCurrentLang = lang => {
    const newLang = availableLangs[lang] || availableLangs.en // with default
    localizations.setLanguage(newLang)
    setCurrentLang(localizations.getLanguage())
  }

  const { labelStrings, pricingPlansStrings } = localizations

  const providerValue = {
    currentLang,
    labelStrings,
    countries: countryOptions(currentLang),
    languageOptions: languageOptions(currentLang),
    pricingPlansStrings,
    changeCurrentLang,
    getDeviceLang,
  }

  return (
    <LocalizationContext.Provider value={providerValue}>
      {children}
    </LocalizationContext.Provider>
  )
}

LocalizationProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  localizationObject: PropTypes.object.isRequired,
  currentPath: PropTypes.string,
}

LocalizationProvider.defaultProps = {
  currentPath: '',
}

export default LocalizationProvider
