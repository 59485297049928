/* eslint-disable import/prefer-default-export */

import React, { useState, useEffect } from 'react'
import MainProvider from '@providers'
import MainLayout from '@layouts'

const event = new Event('routeChange')
let pathname = ''
const target = document.createTextNode(null)

export const onRouteUpdate = ({ location, prevLocation }) => {
  pathname = location.pathname
  target.dispatchEvent(event)
}

export const wrapRootElement = args => {
  const HOC = props => {
    const [currentPath, setCurrentPath] = useState(location.pathname)

    useEffect(() => {
      const onPathChange = () => setCurrentPath(pathname)
      target.addEventListener('routeChange', onPathChange)
      return () => {
        target.removeEventListener('routeChange', onPathChange)
      }
    }, [])

    return <MainProvider {...props} currentPath={currentPath} />
  }

  return <HOC {...args} />
}

export const wrapPageElement = MainLayout
