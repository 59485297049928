import { createGlobalStyle } from 'styled-components'
import { generateMedia } from 'styled-media-query'
import windowSizes from '@constants/windowSizes'

export const USER_INDEXES = {
  tiktok: 'tiktok',
  instagram: 'instagram',
}

const media = generateMedia({
  desktop: `${windowSizes.desktop}px`,
  mid: `${windowSizes.mid}px`,
  tablet: `${windowSizes.tablet}px`,
  smallDesktop: `${windowSizes.smallDesktop}px`,
  mobile: `${windowSizes.mobile}px`,
})

export const generalTheme = {
  media,
  primaryColor: '#FF726F',
  platformColor: '#FF726F',
  platformColorLight: '#FF726F90',
  color: {
    white: '#ffffff',
    black: '#000000',
    primary: '#FF726F',
    primaryLight: '#ff726fba',
    orange: '#F79666',
    brightOrange: '#f78b68',
    yellow: '#FFCA55',
    lightYellow: '#FEFFC1',
    lightPink: '#FFF1EF',
    lightBlack: '#3E4860',
    primaryVeryLight: '#FFE8E5',
    bgLight: '#FFF5F4',
    textColor: '#363636',
    textLightColor: '#707070',
    textDark: '#484848',
    grey: '#888888',
    midGrey: '#e0e0e0',
    tiktokColor: '#4ad1db',
    milk: '#fef5f2',
    cream: '#fff1ef',
    silverChalice: '#ACACAC',
    mercury: '#E2E2E2',
    alto: '#DBDBDB',
    creamBrulee: '#FFDFA2',
    mineShaft: '#393939',
    congoBrown: '#673C3B',
    infludataYellow: '#F9BD4F',
    infludataBlack: '#442424',
    infludataDarkGray: '#784E4E',
    influDataLightBrown: '#F4E8E8',
    infludataMediumGray: '#CBB3B3',
    infludataLightGray: '#EED9D9',
    infludataGray: '#BC9797',
    infludataSalmon: '#FF5D5A',
    infludataOffWhite: '#FFF9F9',
    infludataUltraLightGray: '#FCF3F3',
  },
  mainDeg: (deg = 0) => `linear-gradient(${deg}deg, #FFBC00 0.35%, #FF5D5A 100%)`,
  getTextGradientStyles: (gradient = generalTheme.infludataMainGradient) => `
    display: block;
    background: ${gradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `,
  discardTextGradientStyles: () => `
    background: unset;
    -webkit-background-clip: unset;
    -webkit-text-fill-color: unset;
  `,
  fontFamily: {
    inter: "'Inter', sans-serif",
  },
  fontFamilyInter: "'Inter', sans-serif",
  disabled: `
    pointer-events: none;
    opacity: 0.5;
  `,
  disableBlock: `
    position: relative;
    opacity: 0.5;
    cursor: pointer;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      z-index: 10;
    }
  `,
  flex: {
    centerAll: `
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    column: `
      display: flex;
      flex-direction: column;
      `,
    centerAllColumn: `
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    `,
  },
  infludataMainGradient: 'linear-gradient(90deg, #FFBC00 0.35%, #FF5D5A 100%)',
  infludataLightGradient: 'linear-gradient(90deg, #FFFFFF 0.35%, #F8A288 100%)',
  infludataLightGradientHr: 'linear-gradient(90deg, #FFFFFF 0.35%, #CEB8B8 100%)',
  infludataBrownGradient: 'linear-gradient(90deg, #AE8585 0.35%, #784E4E 100%)',
  infludataDarkOrangeGradient:
    'linear-gradient(310.06deg, rgba(179, 77, 77, 0.95) 13.13%, #E3702F 85.43%)',
  infludataWhiteGradient:
    'linear-gradient(269.73deg, rgba(255, 255, 255, 0.18) -19%, #FFFFFF 100%)',
  bgGradientMainSection:
    'transparent linear-gradient(30deg, #FF726F 0%, #FFCA55 100%) 0% 0% no-repeat padding-box;',
  buttonGradientBakground:
    'transparent linear-gradient(89deg, #FF726F 0%, #FFCA55 120%) 0% 0% no-repeat padding-box',
  bgGradientButton:
    'transparent radial-gradient(circle at 18% 70%, #FF726F 0%, #FFA76C 100%) 0% 0% no-repeat padding-box;',
  bgGradientContactUsTitles:
    'transparent linear-gradient(117deg, #FFCA55 0%, #FF726F 100%) 0% 0% no-repeat padding-box;',
  bgGradientReport:
    'transparent linear-gradient(30deg, #FF726F 0%, #FFCA55 100%) 0% 0% no-repeat padding-box;',
  bgGradientMobileReport:
    'transparent linear-gradient(7deg, #FF726F 0%, #FFCA55 100%) 0% 0% no-repeat padding-box;',
  bgGradientRankingsPage:
    'transparent linear-gradient(63deg, #FF726F 0%, #FFCA55 100%) 0% 0% no-repeat padding-box;',
  bgGradientTabletRankingsPage:
    'transparent linear-gradient(9deg, #FF726F 0%, #FFCA55 100%) 0% 0% no-repeat padding-box;',
  bgGradientMobileRankingsPage:
    'transparent linear-gradient(6deg, #FF726F 0%, #FFCA55 100%) 0% 0% no-repeat padding-box;',

  scrollStyle: `
    &::-webkit-scrollbar {
      width: 3px;
      background-color: #e0e0e0;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent linear-gradient(6deg, #FF726F 0%, #FFCA55 100%) 0% 0% no-repeat padding-box;;
    }
  `,
  getColorPaletteForPlatform: platform => {
    switch (platform) {
      case USER_INDEXES.instagram:
        return {
          main: '#FF5D5A',
          secondary: '#FFBC00',
          disabled: '#CBB3B3',
          dark: '#784E4E',
        }
      case USER_INDEXES.tiktok:
        return {
          main: '#15D9E8',
          secondary: '#02BDC9',
          disabled: '#C6F3F2',
          dark: '#103C3A',
        }
      default:
        return {}
    }
  },
}

export const GlobalStyles = createGlobalStyle`
  body {
    & > * {
      font-family: ${generalTheme.fontFamily.inter};
      color: ${generalTheme.color.white};
    }

    overflow: hidden;
    height: 100vh !important;
    width: 100vw !important;
  }
`
