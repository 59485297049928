import { FRESHWORKS_WIDGET_ID } from '@constants/freshwidget'

export const getIsFreshWidgetFunction = () =>
  typeof window.FreshworksWidget === 'function'

// Set Freshworks Chat widget. Script taken from Freshworks admin panel
export const setFreshWidget = currentLang => {
  window.fwSettings = {
    widget_id: FRESHWORKS_WIDGET_ID,
    locale: currentLang,
  }
  !(function() {
    if (!getIsFreshWidgetFunction()) {
      var n = function() {
        n.q.push(arguments)
      }
      ;(n.q = []), (window.FreshworksWidget = n)
    }
  })()
}

// See https://developers.freshdesk.com/widget-api/
export const openFreshWidget = () => {
  getIsFreshWidgetFunction() && window.FreshworksWidget('open')
}

export const destroyFreshWidget = () => {
  getIsFreshWidgetFunction() && window.FreshworksWidget('destroy')
}
