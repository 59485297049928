import availableLangs from './availableLangs'

export default {
  [availableLangs.en]: {
    Germany: '🇩🇪',
    Austria: '🇦🇹',
    Switzerland: '🇨🇭',
    'United States of America': '🇺🇸',
    Albania: '🇦🇱',
    Algeria: '🇩🇿',
    Argentina: '🇦🇷',
    Armenia: '🇦🇲',
    Australia: '🇦🇺',
    Belarus: '🇧🇾',
    Belgium: '🇧🇪',
    'Bosnia and Herzegovina': '🇧🇦',
    Brazil: '🇧🇷',
    Bolivia: '🇧🇴',
    Bulgaria: '🇧🇬',
    Canada: '🇨🇦',
    Colombia: '🇨🇴',
    Chile: '🇨🇱',
    'Costa Rica': '🇨🇷',
    Croatia: '🇭🇷',
    Cuba: '🇨🇺',
    Cyprus: '🇨🇾',
    'Czech Republic': '🇨🇿',
    Denmark: '🇩🇰',
    'Dominican Republic': '🇩🇴',
    Egypt: '🇪🇬',
    Estonia: '🇪🇪',
    Ecuador: '🇪🇨',
    Finland: '🇫🇮',
    France: '🇫🇷',
    Greece: '🇬🇷',
    Guatemala: '🇬🇹',
    Hungary: '🇭🇺',
    'Hong Kong': '🇭🇰',
    Honduras: '🇭🇳',
    Iceland: '🇮🇸',
    Iran: '🇮🇶',
    Ireland: '🇮🇪',
    Israel: '🇮🇱',
    Italy: '🇮🇹',
    Indonesia: '🇮🇩',
    India: '🇮🇳',
    Japan: '🇯🇵',
    Jamaica: '🇯🇲',
    Libya: '🇱🇾',
    Lithuania: '🇱🇹',
    Luxembourg: '🇱🇺',
    Macedonia: '🇲🇰',
    Mexico: '🇲🇽',
    Morocco: '🇲🇦',
    Netherlands: '🇳🇱',
    'New Zealand': '🇳🇿',
    Nicaragua: '🇳🇮',
    Norway: '🇳🇴',
    Paraguay: '🇵🇾',
    Panama: '🇵🇦',
    Peru: '🇵🇪',
    Philippines: '🇵🇭',
    Poland: '🇵🇱',
    Portugal: '🇵🇹',
    Romania: '🇷🇴',
    'Puerto Rico': '🇵🇷',
    Russia: '🇷🇺',
    'Saudi Arabia': '🇸🇦',
    Serbia: '🇷🇸',
    Singapore: '🇸🇬',
    Slovakia: '🇸🇰',
    Slovenia: '🇸🇮',
    'South Africa': '🇿🇦',
    'South Korea': '🇰🇷',
    Spain: '🇪🇸',
    Sweden: '🇸🇪',
    Taiwan: '🇹🇼',
    Tunisia: '🇹🇳',
    Turkey: '🇹🇷',
    Thailand: '🇹🇭',
    Ukraine: '🇺🇦',
    Uruguay: '🇺🇾',
    'United Kingdom': '🇬🇧',
  },
}
