const SET_GOOGLE_ANALYTICS_QUERY = 'SET_GOOGLE_ANALYTICS_QUERY'

export const setGoogleAnalyticsQuery = googleAnalyticsQuery => ({
  type: SET_GOOGLE_ANALYTICS_QUERY,
  payload: googleAnalyticsQuery,
})

const initialState = {
  googleAnalyticsQuery: {},
}

export default function googleAnalyticsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_GOOGLE_ANALYTICS_QUERY:
      const googleAnalyticsQuery = {}
      Object.keys(action.payload).map(key => {
        if (action.payload[key]) googleAnalyticsQuery[key] = action.payload[key]
      })
      return { ...state, googleAnalyticsQuery }
    default:
      return state
  }
}
