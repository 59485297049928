import { useEffect } from 'react'
import { node } from 'prop-types'

import useScript from '@hooks/useScript'
import useTranslation from '@hooks/useTranslation'

import { FRESHWORKS_WIDGET_INIT_SCRIPT_URL } from '@constants/freshwidget'
import { setFreshWidget } from '@utils/freshwidget'

const FreshWidgetProvider = ({ children }) => {
  const { currentLang } = useTranslation()
  useScript({ src: FRESHWORKS_WIDGET_INIT_SCRIPT_URL })

  useEffect(() => {
    setFreshWidget(currentLang)
  }, [])

  return children
}

FreshWidgetProvider.propTypes = {
  children: node,
}

FreshWidgetProvider.defaultProps = {
  children: '',
}

export default FreshWidgetProvider
