import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles, generalTheme } from '@themes/styledTheme'

const StyledThemeProvider = ({ children }) => (
  <ThemeProvider theme={generalTheme}>
    {children}
    <GlobalStyles />
  </ThemeProvider>
)

StyledThemeProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
}

export default StyledThemeProvider
