import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducers'

const setMiddleWares = () => {
  const middlewares =
    process.env.NODE_ENV !== 'production'
      ? composeWithDevTools(applyMiddleware())
      : applyMiddleware()
  return middlewares
}

const store = createStore(rootReducer, setMiddleWares())

export default store
