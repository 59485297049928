import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'

import store from '@store/store'
import localizationObject from '@localization/localizations'

import StyledThemeProvider from './StyledThemeProvider'
import TikTokRankingsProvider from './TikTokRankingsProvider'
import LocalizationProvider from './LocalizationProvider'
import FreshWidgetProvider from './FreshWidgetProvider'
import PagePathProvider from './PagePathProvider'

// eslint-disable-next-line react/prop-types
const MainProvider = ({ element, currentPath }) => {
  return (
    <Provider store={store}>
      <PagePathProvider currentPagePath={currentPath}>
        <StyledThemeProvider>
          <LocalizationProvider
            localizationObject={localizationObject}
            currentPath={currentPath}
          >
            <FreshWidgetProvider>
              <TikTokRankingsProvider>{element}</TikTokRankingsProvider>
            </FreshWidgetProvider>
          </LocalizationProvider>
        </StyledThemeProvider>
      </PagePathProvider>
    </Provider>
  )
}

MainProvider.propTypes = {
  element: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
}

export default MainProvider
