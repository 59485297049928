import axios from 'axios'

const influDataSource = 'influencerDB'
class HttpService {
  constructor() {
    this.routes = {
      getSuggestedUsers: `${process.env.INFLUDATA_APP_HOST}/api/getUserSuggestionByLetters`,
      contactUs: `${process.env.INFLUDATA_APP_HOST}/api/contactUs`,
      getTopLists: `${process.env.INFLUDATA_APP_HOST}/api/getTopLists`,
      sign: {
        takenEmail: `${process.env.INFLUDATA_APP_HOST}/api/profile/cb/emailtaken`,
        subscribe: `${process.env.INFLUDATA_APP_HOST}/api/profile/cb/subscribe`,
      },
      collab: {
        api: {
          createNewInfludataCustomer: `${process.env.INFLUDATA_APP_HOST}/api/createCustomer`,
        },
      },
    }

    this.getRequestWithAbort = this.abortGetDecorator()
    this.postRequestWithAbort = this.abortPostDecorator()
  }

  abortGetDecorator() {
    let cancelToken = axios.CancelToken.source()
    return async function(url) {
      if (cancelToken) cancelToken.cancel()
      if (!url) return
      cancelToken = axios.CancelToken.source()
      return await this.getRequest(url, { cancelToken: cancelToken.token })
    }
  }

  abortPostDecorator() {
    let cancelToken = axios.CancelToken.source()
    return async function(url, data) {
      if (cancelToken) cancelToken.cancel()
      if (!url) return
      cancelToken = axios.CancelToken.source()
      return await this.postRequest(url, {
        cancelToken: cancelToken.token,
        ...data,
      })
    }
  }

  async getRequest(url, options = {}) {
    const response = await axios.get(url, options)
    return response.data
  }

  async postRequest(url, data = {}) {
    const response = await axios.post(url, { ...data })
    return response.data
  }

  async fetchSuggestedUsers(inputLetters, userindex) {
    return await this.getRequestWithAbort(
      `${this.routes.getSuggestedUsers}?letters=${inputLetters}&index=${userindex}`,
    )
  }

  async fetchContactUs(contactData) {
    return await this.postRequest(this.routes.contactUs, { ...contactData })
  }

  async fetchTopTTLists({
    rankBy = 'views',
    useLastMonth = true,
    count = 100,
    country = '', // can pass few contries e.g. 'Germany,Austria',
    lang = '',
  } = {}) {
    return await this.getRequest(
      `${this.routes.getTopLists}?platform=TT&topList=${rankBy}&sortAbsolute=${useLastMonth}&count=${count}&country=${country}&language=${lang}`,
    )
  }

  async finishSignUp(signUpData) {
    return await this.postRequest(this.routes.influencerDBSignUp, signUpData)
  }

  async fetchIsTakenEmail(email) {
    const response = await fetch(`${this.routes.sign.takenEmail}/${email}`)
    return response
  }

  async signup(body) {
    const response = await fetch(this.routes.sign.subscribe, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body,
    })
    return response
  }

  async createNewCustomer({ username, code, companyData }) {
    return await this.postRequest(this.routes.collab.api.createNewInfludataCustomer, {
      username,
      code,
      companyData,
      source: influDataSource,
    })
  }
}

export default new HttpService()
