import React, { memo } from 'react'
import PropTypes from 'prop-types'

export const PagePathContext = React.createContext({
  currentPath: '',
})

const PagePathProvider = ({ children, currentPagePath }) => {
  const providerValue = {
    currentPath: currentPagePath,
  }

  return (
    <PagePathContext.Provider value={providerValue}>{children}</PagePathContext.Provider>
  )
}

PagePathProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  currentPagePath: PropTypes.string,
}

PagePathProvider.defaultProps = {
  currentPagePath: '',
}

export default memo(PagePathProvider)
