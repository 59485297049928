import availableLangs from './availableLangs'

export const localizedCountries = {
  [availableLangs.en]: {
    all: '🌐 international',
    germany: '🇩🇪 Germany',
    austria: '🇦🇹 Austria',
    switzerland: '🇨🇭 Switzerland',
    usa: ' 🇺🇸 United States',
    albania: '🇦🇱 Albania',
    argentina: '🇦🇷 Argentina',
    armenia: '🇦🇲 Armenia',
    australia: '🇦🇺 Australia',
    belarus: '🇧🇾 Belarus',
    belgium: '🇧🇪 Belgium',
    bosniaHerzegovina: '🇧🇦 Bosnia and Herzegovina',
    brazil: '🇧🇷 Brazil',
    bulgaria: '🇧🇬 Bulgaria',
    canada: '  🇨🇦 Canada',
    colombia: ' 🇨🇴 Columbia',
    chile: ' 🇨🇱 Chile',
    china: ' 🇨🇳 China',
    costaRica: '🇨🇷 Costa Rica',
    croatia: '🇭🇷 Croatia',
    cyprus: ' 🇨🇾  Cyprus',
    czechia: ' 🇨🇿 Czech Republic',
    denmark: ' 🇩🇰 Denmark',
    egypt: '🇪🇬 Egypt',
    estonia: '🇪🇪 Estonia',
    finland: ' 🇫🇮 Finland',
    france: ' 🇫🇷 France',
    greece: ' 🇫🇷 Greece',
    hungary: ' 🇭🇺 Hungary',
    iceland: ' 🇮🇸 Iceland',
    iran: ' 🇮🇶 Iran',
    ireland: ' 🇮🇪 Ireland',
    israel: ' 🇮🇱 Israel',
    italy: ' 🇮🇹 Italy',
    japan: '🇯🇵 Japan',
    lithuania: ' 🇱🇹 Lithuania',
    luxembourg: ' 🇱🇺 Luxembourg',
    macedonia: ' 🇲🇰 Macedonia',
    mexico: ' 🇲🇽 Mexico',
    morocco: ' 🇲🇦 Morocco',
    netherlands: '🇳🇱 Netherlands',
    newZealand: ' 🇳🇿 New Zealand',
    norway: '🇳🇴 Norway',
    poland: '🇵🇱 Poland',
    portugal: '🇵🇹 Portugal',
    romania: '🇷🇴 Romania',
    russia: '🇷🇺 Russia',
    serbia: '🇷🇸 Serbia',
    singapore: '🇸🇬  Singapore',
    slovakia: '🇸🇰 Slovakia',
    slovenia: ' 🇸🇮 Slovenia',
    southAfrica: ' 🇿🇦 South Africa',
    spain: ' 🇪🇸 Spain',
    sweden: '🇸🇪 Sweden',
    turkey: ' 🇹🇷 Turkey',
    ukraine: ' 🇺🇦 Ukraine',
    unitedKingdom: '🇬🇧 United Kingdom',
    other: 'Other',
  },
  [availableLangs.de]: {
    all: '🌐 international',
    germany: '🇩🇪 Deutschland',
    austria: '🇦🇹 Österreich',
    switzerland: '🇨🇭 Schweiz',
    usa: '🇺🇸 Vereinigte Staaten',
    albania: '🇦🇱 Albanien',
    argentina: ' 🇦🇷 Argentinien',
    armenia: '🇦🇲 Armenien',
    australia: '🇦🇺 Australien',
    belarus: '🇧🇾 Weißrussland',
    belgium: '🇧🇪 Belgien',
    bosniaHerzegovina: '🇧🇦 Bosnien und Herzegowina',
    brazil: '🇧🇷 Brasilien',
    bulgaria: '🇧🇬 Bulgarien',
    canada: ' 🇨🇦 Kanada',
    colombia: '🇨🇴 Kolumbien',
    chile: '🇨🇱 Chile',
    china: ' 🇨🇳 China',
    costaRica: '🇨🇷 Costa Rica',
    croatia: ' 🇭🇷 Kroatien',
    cyprus: ' 🇨🇾 Zypern',
    czechia: ' 🇨🇿 Tschechien',
    denmark: ' 🇩🇰 Dänemark',
    egypt: ' 🇪🇬 Ägypten',
    estonia: '🇪🇪 Estland',
    finland: ' 🇫🇮 Finnland',
    france: '🇫🇷 Frankreich',
    greece: ' 🇬🇷 Griechenland',
    hungary: '🇭🇺 Ungarn',
    iceland: ' 🇮🇸 Island',
    iran: '🇮🇶 Iran',
    ireland: ' 🇮🇪 Irland',
    israel: ' 🇮🇱 Israel',
    italy: '🇮🇹 Italien',
    japan: ' 🇯🇵 Japan',
    lithuania: '🇱🇹 Litauen',
    luxembourg: ' 🇱🇺 Luxemburg',
    macedonia: ' 🇲🇰 Mazedonien',
    mexico: ' 🇲🇽 Mexiko',
    morocco: ' 🇲🇦 Marokko',
    netherlands: '🇳🇱 Niederlande',
    newZealand: ' 🇳🇿 Neuseeland',
    norway: ' 🇳🇴  Norwegen',
    poland: ' 🇵🇱 Polen',
    portugal: ' 🇵🇹 Portugal',
    romania: '🇷🇴 Rumänien',
    russia: '🇷🇺 Russland',
    serbia: ' 🇷🇸 Serbien',
    singapore: '🇸🇬 Singapur',
    slovakia: '🇸🇰 Slowakei',
    slovenia: '🇸🇮 Slowenien',
    southAfrica: ' 🇿🇦 Südafrika',
    spain: ' 🇪🇸 Spanien',
    sweden: ' 🇸🇪 Schweden',
    turkey: ' 🇹🇷 Türkei',
    ukraine: ' 🇺🇦 Ukraine',
    unitedKingdom: ' 🇬🇧 Großbritannien',
    other: 'Sonstiges',
  },
}

export const countryOptions = lang => [
  { value: '', label: localizedCountries[lang].all },
  { value: 'Germany', label: localizedCountries[lang].germany },
  { value: 'Austria', label: localizedCountries[lang].austria },
  { value: 'Switzerland', label: localizedCountries[lang].switzerland },
  { value: 'Italy', label: localizedCountries[lang].italy },
  { value: 'Spain', label: localizedCountries[lang].spain },
  { value: 'United Kingdom', label: localizedCountries[lang].unitedKingdom },
  { value: 'United States of America', label: localizedCountries[lang].usa },
  { value: 'Finland', label: localizedCountries[lang].finland },
  { value: 'France', label: localizedCountries[lang].france },
  { value: 'Netherlands', label: localizedCountries[lang].netherlands },
  { value: 'China', label: localizedCountries[lang].china },
  { value: 'Other', label: localizedCountries[lang].other },
  { value: 'Albania', label: localizedCountries[lang].albania },
  // //{ value: 'Algeria', label: localizedCountries[lang].Algeria },
  // //{ value: 'Andorra', label: localizedCountries[lang].Andorra },
  // //{ value: 'Angola', label: localizedCountries[lang].Angola },
  // //{ value: 'Antigua and Barbuda', label: localizedCountries[lang].Antigua and Barbuda },
  { value: 'Argentina', label: localizedCountries[lang].argentina },
  { value: 'Armenia', label: localizedCountries[lang].armenia },
  // //{ value: 'Aruba', label: localizedCountries[lang].Aruba },
  { value: 'Australia', label: localizedCountries[lang].australia },
  // //{ value: 'Azerbaijan', label: localizedCountries[lang].Azerbaijan },
  // //{ value: 'Bahamas', label: localizedCountries[lang].Bahamas },
  // //{ value: 'Bahrain', label: localizedCountries[lang].Bahrain },
  // //{ value: 'Bangladesh', label: localizedCountries[lang].Bangladesh },
  // //{ value: 'Barbados', label: localizedCountries[lang].Barbados },
  { value: 'Belarus', label: localizedCountries[lang].belarus },
  { value: 'Belgium', label: localizedCountries[lang].belgium },
  // //{ value: 'Belize', label: localizedCountries[lang].Belize },
  // //{ value: 'Benin', label: localizedCountries[lang].Benin },
  // //{ value: 'Bhutan', label: localizedCountries[lang].Bhutan },
  // //{ value: 'Bolivia', label: localizedCountries[lang].Bolivia },
  {
    value: 'Bosnia and Herzegovina',
    label: localizedCountries[lang].bosniaHerzegovina,
  },
  // //{ value: 'Botswana', label: localizedCountries[lang].Botswana },
  { value: 'Brazil', label: localizedCountries[lang].brazil },
  // //{ value: 'Brunei', label: localizedCountries[lang].Brunei },
  { value: 'Bulgaria', label: localizedCountries[lang].bulgaria },
  // //{ value: 'Burkina Faso', label: localizedCountries[lang].Burkina Faso },
  // //{ value: 'Burma', label: localizedCountries[lang].Burma },
  // //{ value: 'Burundi', label: localizedCountries[lang].Burundi },
  // //{ value: 'Cambodia', label: localizedCountries[lang].Cambodia },
  // //{ value: 'Cameroon', label: localizedCountries[lang].Cameroon },
  { value: 'Canada', label: localizedCountries[lang].canada },
  // //{ value: 'Cabo Verde', label: localizedCountries[lang].Cabo Verde },
  // //{ value: 'Central African Republic', label: localizedCountries[lang].Central African Republic },
  // //{ value: 'Chad', label: localizedCountries[lang].Chad },
  { value: 'Chile', label: localizedCountries[lang].chile },
  // //{ value: 'China', label: localizedCountries[lang].China },
  { value: 'Colombia', label: localizedCountries[lang].colombia },
  // //{ value: 'Comoros', label: localizedCountries[lang].Comoros },
  // //{ value: 'Congo', label: localizedCountries[lang].Congo },
  { value: 'Costa Rica', label: localizedCountries[lang].costaRica },
  // //{ value: 'Cote d\' Ivoire', label: localizedCountries[lang].Cote d\' Ivoire },
  { value: 'Croatia', label: localizedCountries[lang].croatia },
  // //{ value: 'Cuba', label: localizedCountries[lang].Cuba },
  // //{ value: 'Curacao', label: localizedCountries[lang].Curacao },
  { value: 'Cyprus', label: localizedCountries[lang].cyprus },
  { value: 'Czechia', label: localizedCountries[lang].czechia },
  { value: 'Denmark', label: localizedCountries[lang].denmark },
  // //{ value: 'Djibouti', label: localizedCountries[lang].Djibouti },
  // //{ value: 'Dominica', label: localizedCountries[lang].Dominica },
  // //{ value: 'Dominican Republic', label: localizedCountries[lang].Domincan Republic },
  // //{ value: 'East Timor', label: localizedCountries[lang].East Timor },
  // //{ value: 'Ecuador', label: localizedCountries[lang].Ecuador },
  { value: 'Egypt', label: localizedCountries[lang].egypt },
  // //{ value: 'El Salvador', label: localizedCountries[lang].El Salvador },
  // //{ value: 'Equatorial Guinea', label: localizedCountries[lang].Equatorial Guinea },
  // //{ value: 'Eritrea', label: localizedCountries[lang].Eritrea },
  { value: 'Estonia', label: localizedCountries[lang].estonia },
  // //{ value: 'Ethiopia', label: localizedCountries[lang].Ethiopia },
  // //{ value: 'Fiji', label: localizedCountries[lang].Fiji },
  // //{ value: 'Gabon', label: localizedCountries[lang].Gabon },
  // //{ value: 'Gambia', label: localizedCountries[lang].Gambia },
  // //{ value: 'Georgia', label: localizedCountries[lang].Georgia },
  // //{ value: 'Ghana', label: localizedCountries[lang].Ghana },
  { value: 'Greece', label: localizedCountries[lang].greece },
  // //{ value: 'Grenada', label: localizedCountries[lang].Grenada },
  // //{ value: 'Guatemala', label: localizedCountries[lang].Guatemala },
  // //{ value: 'Guinea', label: localizedCountries[lang].Guinea },
  // //{ value: 'Guinea-Bissau', label: localizedCountries[lang].Guinea-Bissau },
  // //{ value: 'Guyana', label: localizedCountries[lang].Guyana },
  // //{ value: 'Haiti', label: localizedCountries[lang].Haiti },
  // //{ value: 'Honduras', label: localizedCountries[lang].Honduras },
  // //{ value: 'Hong Kong', label: localizedCountries[lang].Hong Kong },
  { value: 'Hungary', label: localizedCountries[lang].hungary },
  { value: 'Iceland', label: localizedCountries[lang].iceland },
  // //{ value: 'India', label: localizedCountries[lang].India },
  // //{ value: 'Indonesia', label: localizedCountries[lang].Indonesia },
  { value: 'Iran', label: localizedCountries[lang].iran },
  // //{ value: 'Iraq', label: localizedCountries[lang].Iraq },
  { value: 'Ireland', label: localizedCountries[lang].ireland },
  { value: 'Israel', label: localizedCountries[lang].israel },
  // //{ value: 'Jamaica', label: localizedCountries[lang].Jamaica },
  { value: 'Japan', label: localizedCountries[lang].japan },
  // //{ value: 'Jordan', label: localizedCountries[lang].Jordan },
  // //{ value: 'Kazakhstan', label: localizedCountries[lang].Kazakhstan },
  // //{ value: 'Kenya', label: localizedCountries[lang].Kenya },
  // //{ value: 'Kiribati', label: localizedCountries[lang].Kiribati },
  // //{ value: 'Kosovo', label: localizedCountries[lang].Kosovo },
  // //{ value: 'Kuwait', label: localizedCountries[lang].Kuwait },
  // //{ value: 'Kyrgyzstan', label: localizedCountries[lang].Kyrgyzstan },
  // //{ value: 'Laos', label: localizedCountries[lang].Laos },
  // //{ value: 'Latvia', label: localizedCountries[lang].Latvia },
  // //{ value: 'Lebanon', label: localizedCountries[lang].Lebanon },
  // //{ value: 'Lesotho', label: localizedCountries[lang].Lesotho },
  // //{ value: 'Liberia', label: localizedCountries[lang].Liberia },
  // //{ value: 'Libya', label: localizedCountries[lang].Libya },
  // //{ value: 'Liechtenstein', label: localizedCountries[lang].Liechtenstein },
  { value: 'Lithuania', label: localizedCountries[lang].lithuania },
  { value: 'Luxembourg', label: localizedCountries[lang].luxembourg },
  // //{ value: 'Macau', label: localizedCountries[lang].Macau },
  { value: 'Macedonia', label: localizedCountries[lang].macedonia },
  // //{ value: 'Madagascar', label: localizedCountries[lang].Madagascar },
  // //{ value: 'Malawi', label: localizedCountries[lang].Malawi },
  // //{ value: 'Malaysia', label: localizedCountries[lang].Malaysia },
  // //{ value: 'Maldives', label: localizedCountries[lang].Maldives },
  // //{ value: 'Mali', label: localizedCountries[lang].Mali },
  // //{ value: 'Malta', label: localizedCountries[lang].Malta },
  // //{ value: 'Marshall Islands', label: localizedCountries[lang].Marshall Islands },
  // //{ value: 'Mauritania', label: localizedCountries[lang].Mauritania },
  // //{ value: 'Mauritius', label: localizedCountries[lang].Mauritius },
  { value: 'Mexico', label: localizedCountries[lang].mexico },
  // //{ value: 'Micronesia', label: localizedCountries[lang].Micronesia },
  // //{ value: 'Moldova', label: localizedCountries[lang].Moldova },
  // //{ value: 'Monaco', label: localizedCountries[lang].Monaco },
  // //{ value: 'Mongolia', label: localizedCountries[lang].Mongolia },
  // //{ value: 'Montenegro', label: localizedCountries[lang].Montenegro },
  { value: 'Morocco', label: localizedCountries[lang].morocco },
  // //{ value: 'Mozambique', label: localizedCountries[lang].Mozambique },
  // //{ value: 'Namibia', label: localizedCountries[lang].Namibia },
  // //{ value: 'Nauru', label: localizedCountries[lang].Nauru },
  // //{ value: 'Nepal', label: localizedCountries[lang].Nepal },
  { value: 'New Zealand', label: localizedCountries[lang].newZealand },
  // //{ value: 'Nicaragua', label: localizedCountries[lang].Nicaragua },
  // //{ value: 'Niger', label: localizedCountries[lang].Niger },
  // //{ value: 'Nigeria', label: localizedCountries[lang].Nigeria },
  // //{ value: 'North Korea', label: localizedCountries[lang].North Korea },
  { value: 'Norway', label: localizedCountries[lang].norway },
  // //{ value: 'Oman', label: localizedCountries[lang].Oman },
  // //{ value: 'Pakistan', label: localizedCountries[lang].Pakistan },
  // //{ value: 'Palau', label: localizedCountries[lang].Palau },
  // //{ value: 'Palestinian Territories', label: localizedCountries[lang].Palestinian Territories },
  // //{ value: 'Panama', label: localizedCountries[lang].Panama },
  // //{ value: 'Papua New Guinea', label: localizedCountries[lang].Papua New Guinea },
  // //{ value: 'Paraguay', label: localizedCountries[lang].Paraguay },
  // //{ value: 'Peru', label: localizedCountries[lang].Peru },
  // //{ value: 'Philippines', label: localizedCountries[lang].Philippines },
  { value: 'Poland', label: localizedCountries[lang].poland },
  { value: 'Portugal', label: localizedCountries[lang].portugal },
  // //{ value: 'Qatar', label: localizedCountries[lang].Qatar },
  { value: 'Romania', label: localizedCountries[lang].romania },
  { value: 'Russia', label: localizedCountries[lang].russia },
  // //{ value: 'Rwanda', label: localizedCountries[lang].Rwanda },
  // //{ value: 'Saint Kitts and Nevis', label: localizedCountries[lang].Saint Kitts and Nevis },
  // //{ value: 'Saint Lucia', label: localizedCountries[lang].Saint Lucia },
  // //{ value: 'Saint Vincent and the Grenadines', label: localizedCountries[lang].Saint Vincent and the Grenadines },
  // //{ value: 'Samoa', label: localizedCountries[lang].Samoa },
  // //{ value: 'San Marino', label: localizedCountries[lang].San Marino },
  // //{ value: 'Sao Tome and Principe', label: localizedCountries[lang].Sao Tome and Principe },
  // //{ value: 'Saudi Arabia', label: localizedCountries[lang].Saudi Arabia },
  // //{ value: 'Senegal', label: localizedCountries[lang].Senegal },
  { value: 'Serbia', label: localizedCountries[lang].serbia },
  // //{ value: 'Seychelles', label: localizedCountries[lang].Seychelles },
  // //{ value: 'Sierra Leone', label: localizedCountries[lang].Sierra Leone },
  { value: 'Singapore', label: localizedCountries[lang].singapore },
  // //{ value: 'Sint Maarten', label: localizedCountries[lang].Sint Maarten },
  { value: 'Slovakia', label: localizedCountries[lang].slovakia },
  { value: 'Slovenia', label: localizedCountries[lang].slovenia },
  // //{ value: 'Solomon Islands', label: localizedCountries[lang].Solomon Islands },
  // //{ value: 'Somalia', label: localizedCountries[lang].Somalia },
  { value: 'South Africa', label: localizedCountries[lang].southAfrica },
  // //{ value: 'South Sudan', label: localizedCountries[lang].South Sudan },
  // //{ value: 'Sri Lanka', label: localizedCountries[lang].Sri Lanka },
  // //{ value: 'Sudan', label: localizedCountries[lang].Sudan },
  // //{ value: 'Suriname', label: localizedCountries[lang].Suriname },
  // //{ value: 'Swaziland', label: localizedCountries[lang].Swaziland },
  { value: 'Sweden', label: localizedCountries[lang].sweden },
  // //{ value: 'Syria', label: localizedCountries[lang].Syria },
  // //{ value: 'Taiwan', label: localizedCountries[lang].Taiwan },
  // //{ value: 'Tajikistan', label: localizedCountries[lang].Tajikistan },
  // //{ value: 'Tanzania', label: localizedCountries[lang].Tanzania },
  // //{ value: 'Thailand', label: localizedCountries[lang].Thailand },
  // //{ value: 'Timor-Leste', label: localizedCountries[lang].Timor-Leste },
  // //{ value: 'Togo', label: localizedCountries[lang].Togo },
  // //{ value: 'Tonga', label: localizedCountries[lang].Tonga },
  // //{ value: 'Trinidad and Tobago', label: localizedCountries[lang].Trinidad and Tobago },
  // //{ value: 'Tunisia', label: localizedCountries[lang].Tunisia },
  { value: 'Turkey', label: localizedCountries[lang].turkey },
  // //{ value: 'Turkmenistan', label: localizedCountries[lang].Turkmenistan },
  // //{ value: 'Tuvalu', label: localizedCountries[lang].Tuvalu },
  // //{ value: 'Uganda', label: localizedCountries[lang].Uganda },
  { value: 'Ukraine', label: localizedCountries[lang].ukraine },
  // //{ value: 'United Arab Emirates', label: localizedCountries[lang].United Arab Emirates },
  // //{ value: 'Uruguay', label: localizedCountries[lang].Uruguay },
  // //{ value: 'Uzbekistan', label: localizedCountries[lang].Uzbekistan },
  // //{ value: 'Vanuatu', label: localizedCountries[lang].Vanuatu },
  // //{ value: 'Venezuela', label: localizedCountries[lang].Venezuela },
  // //{ value: 'Vietnam', label: localizedCountries[lang].Vietnam },
  // //{ value: 'Yemen', label: localizedCountries[lang].Yemen },
  // //{ value: 'Zambia', label: localizedCountries[lang].Zambia },
  // //{ value: 'Zimbabwe', label: 'Zimbabwe' }
]
