import availableLangs from './availableLangs'

export default {
  [availableLangs.en]: {
    all: 'All',
    german: 'German',
    english: 'English',
    spanish: 'Spanish',
    french: 'French',
    portuguese: 'Portuguese',
    italian: 'Italian',
    danish: 'Danish',
    russian: 'Russian',
    latvian: 'Latvian',
    czech: 'Czech',
    polish: 'Polish',
    arabic: 'Arabic',
    bulgarian: 'Bulgarian',
    dutch: 'Dutch',
    finnish: 'Finnish',
    japanese: 'Japanese',
    hungarian: 'Hungarian',
    norwegian: 'Norwegian',
    greek: 'Greek',
    romanian: 'Romanian',
    turkish: 'Turkish',
    ligurian: 'Ligurian',
    chinese: 'Chinese',
  },
  [availableLangs.de]: {
    all: 'alle',
    german: 'deutsch',
    english: 'englisch',
    spanish: 'spanisch',
    french: 'französisch',
    portuguese: 'portugiesisch',
    italian: 'italienisch',
    danish: 'dänisch',
    russian: 'russisch',
    latvian: 'lettisch',
    czech: 'tschechisch',
    polish: 'polnisch',
    arabic: 'arabisch',
    bulgarian: 'bulgarisch',
    dutch: 'niederländisch',
    finnish: 'finnisch',
    japanese: 'japanisch',
    hungarian: 'ungarisch',
    norwegian: 'norwegisch',
    greek: 'griechisch',
    romanian: 'rumänisch',
    turkish: 'türkisch',
    ligurian: 'ligurisch',
    chinese: 'Chinesisch',
  },
}
