module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Infludata","short_name":"Infludata","start_url":"/","display":"minimal-ui","icon":"/opt/build/repo/static/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a52605d5407640a2b088513b918ff368"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
