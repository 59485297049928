import availableLangs from './availableLangs'

export default {
  [availableLangs.en]: {
    pageTitle: 'influData - TikTok and Instagram Search Engine',
    pageKeywords:
      'instagram, tiktok, search, engine, audience, quality, database, data, creator, find',
    pageDescription:
      'First search engine for Instagram and TikTok influencers and brands. Find the best fitting creator, filter by keywords, gender, location and check the audience.',
    pricingAndFeatures: 'Pricing and Features',
    tiktokRankings: 'TikTok Rankings',
    username: 'Username',
    faq: 'FAQ',
    tryForFree: 'Try For Free',
    login: 'Log-In',
    imprint: 'Imprint',
    privacyPolicy: 'Privacy Policy',
    termAndConditions: 'Terms and Conditions',
    copyright: 'Copyright influData',
    byOnlinePunks: 'by weCreate',
    goToTop: 'Back to Top',
    question: 'Question',
    send: 'Send',
    emailAddress: 'E-mail address',
    name: 'Name',
    message: 'Message',
    notValidEmail: 'Not a valid e-mail address.',
    notValidName: 'Please type your name.',
    notValidMessage: 'Please write minimum 60 characters.',
    cookieMsg: 'This website uses cookies to enhance the user experience.',
    thankyou: 'Thank you!',
    sorry: 'Sorry...',
    weWillComeBacktoYou: 'We will come back to you as soon as possible!',
    errorMsg: 'An error has occured. We are working on it. Please try again later.',
    sending: 'Sending...',
    yearly: '12 Months',
    ninetyDays: '90 days',
    reportsIncluded: 'reports included',
    needMoreReports: 'Need more Audience Reports',
    mainPerformance: {
      title:
        'Save 86% of your time on influencer research* and truly reach your target audience.',
      list: [
        {
          text:
            'Discovery - made for marketers:[b] Find 11 million influencers on TikTok and Instagram',
          bold: ['Discovery - made for marketers:'],
        },
        {
          text:
            '20 scores & analytics[b] per influencer guarantee the best quality for your campaign',
          bold: ['20 scores & analytics'],
        },
        {
          text:
            'In-depth Audience Reports:[b] Get specific data on the followers of each influencer',
          bold: ['In-depth Audience Reports:'],
        },
        {
          text: 'Campaign Tracking:[b] Automated # and @ detection of stories and posts',
          bold: ['Campaign Tracking:'],
        },
        //{ text: 'Made for teams: Collaborate, share and collect', bold: 'all' },
        { text: 'New: Instagram Reels[b] - Use the still undervalued channel and discover viral influencers in your niche.', bold: ['New: Instagram Reels'] },

      ],
      remark:
        '*we compared agencies that did research directly via social-media apps before using influData',
    },
    analyseYourInfluencer: 'Analyse your influencer',
    noUserFound: 'No users found',
    companiesThatTrustUs: 'Companies that trust us',
    features: {
      discover: {
        title: 'Discover',
        text:
          'Our intuitive keyword search allows you to find the exact creators you are after – no matter how exotic your niche may be.',
      },
      check: {
        title: 'Check',
        text:
          'Always authentic: influData’s Quality Rating makes it easy to separate the real deal from an imposter.',
      },
      activate: {
        title: 'Activate',
        text:
          'We will give you all the contact information you need and – if wanted – even assist you with the copy-writing. It’s that simple!',
      },
      track: {
        title: 'Track',
        text:
          'We analyze and track the activity of every creator you choose to cooperate with. Lean back, and let us keep track of your campaign mentions.',
      },
      audienceInsights: {
        title: 'Audience Insights',
        text:
          'Check the audience of every creator. Unlike other platforms, we don’t charge for individual reports and provide you will all information of each creator.',
      },
      storeAndCollect: {
        title: 'Store and Collect',
        text:
          'Create Folders and add creators. Give private ratings and add your individual notes.',
      },
    },
    insideInfludata: {
      title: 'Inside influData',
      items: {
        highPerformance: {
          title: 'High Performance Search Tools',
          text:
            'Find in milliseconds your best fitting creator with our live search, even with complex combinations of keywords, gender and location.',
        },
        metrics: {
          title: 'Creator Metrics at a Glance',
          text:
            'Everything you need to know about a creator is neatly displayed in our profile page. Detailed Quality Scores, Audience Reports, Follower Graphs, Latest Posts or any metrics that could help you.',
        },
        order: {
          title: 'Sophisticated Creator and Campaign Tracking',
          text:
            'We provide you with an overview of the latest stories and posts from the creators you collaborate with and highlight the mentions and tags of your brand. We provide statistic of how your campaigns perform and notify you about your goals or new tags. (Coming soon)',
        },
      },
    },
    keyFeatures: {
      title: 'Your next generation marketing app',
      items: [
        { bubbled: '2.5 Mio', title: 'TikTok Creators' },
        { bubbled: '8.5 Mio', title: 'Instagram Influencers' },
        { bubbled: '3.5 Mio', title: 'Instagram Brand Accounts' },
        {
          bubbled: '# 1',
          title: 'TikTok and Instagram Experts involved in Planning and Development',
        },
      ],
    },
    testimonials: {
      title: 'Testimonials',
      christianKuper: {
        name: 'Christian Kuper',
        position: 'CEO at Vincent Vegan',
        text:
          "For our vegan burger restaurants in Hamburg, Berlin and soon Leipzig we were searching vegetarian or vegan creators. With the use of influData we didn't only reach thousands of potential customers, but also got high-quality and authentic content.",
      },
      julia: {
        name: 'Julia Schultz-Ering',
        position: 'Digital Marketing Manager at CatInTheBox',
        text:
          'influData makes it so much easier to find, qualifiy and activate creators from different regions and with different backgrounds, styles, age - you name it! All the time that was invested into research before, now can be used for management and strategy.',
      },
      sarahEmmerich: {
        name: 'Sarah Emmerich',
        position: 'Social Media Expert (Self-Employed)',
        text:
          'InfluData makes it easier for me to find influencers in different niches and sizes for my clients. Also, InfluData is my most valuable tool in analyzing influencers I’ve already selected, because I get a quality analysis about the target audience and all the important insights without even having contacted the influencer or their management.',
      },
    },
    contactUs: {
      title: 'Hola',
      subtitle: 'We are here for you!',
      text:
        'Please write below your question and we will come back to you. We usually respond in few hours.',
    },
    rankings: {
      header: 'Top Rankings of TikTok Influencers & Creators by Country',
      description:
        'influData is providing you with the rankings of the current top influencers by followers and views. We also provide you with the most used songs and hashtags (coming soon). You are able to check the growth in the last 30 days or the all time lists.',
      label: 'TikTok Rankings',
      byViews: 'By Views',
      byFollowers: 'By Followers',
      topHashtags: 'Top Hashtags',
      topSongs: 'Top Songs',
      last30Days: '30 days',
      allTime: 'All Time',
      rank: 'Rank',
      account: 'Username',
      name: '',
      growth: 'Growth',
      views: 'Views',
      followers: 'Followers',
      seeTop100: 'See Top 100',
      noUsersFound: 'No users found. Please change search parameters.',
      loadError: 'Failed to load rankings. Please try again later.',
      reload: 'Reload',
    },
    influencerDB_SEO: 'CEO of InfluencerDB',
    influencerDBData: `influData is our partner for influencer discovery and analytics. Everyone looking for a tool to find and analyze creator accounts on Instagram and TikTok is in best hands with influData! `,
    findAnalyzeCreatorAcc: 'Find and analyze creator accounts with influData. ',
    saveManageCreatorContacts:
      'Save and manage your creator contacts & campaigns with InfluencerDB. ',
    benefitPartnership: 'Benefit now from our InfluencerDB x influData partnership! ',
    freeInfludataTwoWeeks: `As an InfluencerDB lead, you can use influData for two weeks for free! Get in touch
    now for your free test phase, take a look around and decide whether influData is the right tool for you. `,
    firstName: 'First Name',
    lastName: 'Last Name',
    company: 'Company',
    phone: 'Phone',
    companySize: 'Company Size',
    selectCompanySize: 'Select',
    position: 'Position',
    selectPosition: 'Select Position',
    required: 'required',
    passwordLimit:
      'Password must have minimum 6 characters and should have at least one big letter.',
    finish: 'Send',
    selectCountry: 'Select country',
    password: 'Password',
    country: 'Country',
    email: 'E-Mail',
    reenterNotValidEmail: 'Email is not valid. Please re-enter email.',
    next: 'Next',
    termsOfService: 'Terms of Service',
    and: 'and',
    bySigningUpIAgreeTo: 'By signing up, I agree to the',
    tellUsAbout: 'Tell us about ',
    yourBusiness: 'your business ',
    getYour: 'Get your ',
    personalDemo: 'Personal demo ',
    andTry: 'and try ',
    proPlanFor2Weeks: 'our Pro Plan for 2 weeks ',
    thankYouForSignUp:
      'Thank you for getting in contact with us.\n\n Please check your mails and schedule a demo with one of our Success Managers.',
    pleaseLogIn: 'You already have an account, please log in',
    tryApp: 'Sign Up &',
    forFree: 'try all features',
    noPayment: 'non-binding and no payment details needed',
    userAgree: 'By signing up, I agree to the influData',
    pageNotFound: "This page wasn't found",
    goBackHome: 'go back home',
    top: 'INSTAGRAM RANKINGS',
    topInfluencers: {
      topInfluenceTitle:
        'Find the top influencers on Instagram by Country, City or Category',
      topInfluenceSubtitle: `influData provides you with the top influencers in your niche. These influencers perform best in followers, 
        growth, engagement and authenticity. Check out influData to find over 12 million influencers.`,
      topInfluenceInputPlaceholder: 'Add cities, countries or categories',
      categoryDetailTopTitleFirst: 'The Top',
      categoryDetailTopTitleSecond: 'Instagram Influencers',
      categoryDetailTopTitleThird: 'from',
      showMore: 'show more',
      byCounty: 'By Country',
      byCity: 'By City',
      byCategoryAndCity: 'By Category and City',
      byCategoryAndCountry: 'By Category and Country',
      categoryDetailTopLink: 'Search more in detail on Infludata',
      categoryDetailTotalInfluencers: 'TOTAL INFLUENCERS',
      categoryDetailAverageEngagement: 'AVERAGE ENGAGEMENT',
      categoryDetailExpectedReach: 'EXPECTED REACH',
      categoryDetailFollowersInTotal: 'FOLLOWERS IN TOTAL',
      categoryDetailChartTitle: 'Audience of all influencers',
      categoryDetailTableTitleFirst: 'Top growing influencers',
      categoryDetailTableTitleSecond: 'for your search',
      categoryDetailTableGrowthFirst: 'GROWTH',
      categoryDetailTableGrowthLast: '(LAST 30 DAYS)',
      categoryDetailTableUsername: 'USERNAME',
      categoryDetailTableLocation: 'LOCATION',
      categoryDetailTableLanguage: 'LANGUAGE',
      categoryDetailTableName: 'NAME',
      categoryDetailTableBiography: 'BIOGRAPHY',
      categoryDetailTableFollowers: 'FOLLOWERS',
      categoryDetailTableEngagement: 'ENGAGEMENT',
      categoryDetailBottomTitleFirst: 'Get the audiences of over 10 million',
      categoryDetailBottomTitleSecond: 'influencers and find the best',
      categoryDetailBottomTitleLasts: 'fitting influencer for your brand today',
      categoryDetailBottomButton: 'START YOUR FREE TRIAL',
      categoryDetailBottomAfterButtonText: 'and get all your results',
      chartCountry: 'Country',
      chartCity: 'City',
      chartLanguage: 'Language',
      chartAgeGroups: 'Age Groups',
      chartGender: 'Gender',
      pieChartAttrFemale: 'Female',
      pieChartAttrMale: 'Male',
    },
  },

  // -------------------GERMAN----------------
  [availableLangs.de]: {
    pageTitle: 'influData - TikTok und Instagram Suchmaschine',
    pageKeywords:
      'instagram, tiktok, suche, suchmaschine, audience, quality, daten, data, creator, finde',
    pageDescription:
      'Die erste Suchmaschine für Instagram und TikTok influencers und brands. Über 7 Millionen Daten und unbegrenzte Qualitätsanalysen und Reports.',
    pricingAndFeatures: 'Preise & Features',
    tiktokRankings: 'TikTok-Rangliste',
    username: 'Nutzername',
    faq: 'FAQ',
    tryForFree: 'Kostenlos testen',
    login: 'Log-In',
    imprint: 'Impressum',
    privacyPolicy: 'Datenschutz',
    termAndConditions: 'Allgemeine Geschäftsbedingungen',
    copyright: 'Copyright influData',
    byOnlinePunks: 'by weCreate',
    goToTop: 'Zurück nach oben',
    question: 'Frage',
    send: 'Senden',
    emailAddress: 'E-Mail-Addresse',
    name: 'Name',
    message: 'Nachricht',
    notValidEmail: 'Keine gültige E-Mail-Adresse.',
    notValidName: 'Bitte geben Sie Ihren Namen ein.',
    notValidMessage: 'Bitte schreiben Sie mindestens 60 Zeichen.',
    cookieMsg: 'Diese Website verwendet Cookies, um die Benutzererfahrung zu verbessern.',
    thankyou: 'Vielen Dank!',
    sorry: 'Es tut uns leid...',
    weWillComeBacktoYou: 'Wir werden uns so schnell wie möglich bei Ihnen melden!',
    errorMsg:
      'Ein Fehler ist aufgetreten. Wir arbeiten daran. Bitte versuchen Sie es später noch einmal.',
    sending: 'Senden...',
    yearly: '12 Monate',
    ninetyDays: '90 Tage',
    reportsIncluded: 'Reports inklusive',
    needMoreReports: 'Mehr Audience Reports nötig',
    mainPerformance: {
      title: 'Spare 86% deiner Zeit bei der Influencer-Recherche* und erreiche deine Zielgruppe besser.',
      list: [
        {
          text:
            'Discovery für Profis:[b] Finde 11 Millionen Influencer auf Instagram und TikTok',
          bold: ['Discovery für Profis:'],
        },
        {
          text:
            '20 Scores & Analysen[b] pro Influencer garantieren die beste Qualität für Kampagnen',
          bold: ['20 Scores & Analysen'],
        },
        {
          text:
            'Ausführliche Audience Reports:[b] Aktuelle und detaillierte Daten des Publikums von jedem Influencer',
          bold: ['Ausführliche Audience Reports:'],
        },
        {
          text:
            'Kampagnen-Tracking:[b] Automatisierte #- und @-Erkennung in Storys und Posts',
          bold: ['Kampagnen-Tracking:'],
        },
        // { text: 'Gebaut für Teams: Zusammenarbeiten, teilen und sammeln', bold: 'all' },
        {
          text:
            'Neu: Instagram Reels[b] - Nutze das noch unentdecke Potential und finde virale Influencer.',
          bold: ['Neu: Instagram Reels'],
        },
      ],
      remark:
        '*verglichen wurden Agenturen, die zuvor direkt über Social-Media Plattformen nach Influencern suchten.',
    },
    analyseYourInfluencer: 'Analysiere einen Influencer',
    noUserFound: 'Keinen Account gefunden',
    companiesThatTrustUs: 'Unternehmen, die uns vertrauen',
    features: {
      discover: {
        title: 'Entdecken',
        text:
          'Unsere intuitive Keyword-Suche ermöglicht es dir, genau die Creator zu finden, nach denen du suchst - egal wie exotisch deine Nische sein mag.',
      },
      check: {
        title: 'Prüfen',
        text:
          'Immer authentisch: Die Qualitätsbewertung von influData macht es dir einfach, das echte Profil von einem Fake-Profil zu trennen.',
      },
      activate: {
        title: 'Kontaktieren',
        text:
          'Wir geben dir alle Kontaktinformationen, die du benötigst und unterstützen Dich - falls gewünscht - sogar beim Verfassen von Texten. So einfach ist das!',
      },
      track: {
        title: 'Tracken',
        text:
          'Wir analysieren und verfolgen die Aktivitäten aller Creator, mit denen du zusammenarbeitest. Kein tägliches manuelles Checken der Storys und Posts mehr nötig.',
      },
      audienceInsights: {
        title: 'Einblicke in das Publikum',
        text:
          'Überprüfen Sie die Audience jedes Creators. Im Gegensatz zu anderen Plattformen zahlst du nicht für einzelne Reports, sondern kannst dir so viele Audiences anschauen, wie du magst.',
      },
      storeAndCollect: {
        title: 'Ordnen',
        text:
          'Erstelle Ordner und füge Creator hinzu. Wir geben dir Platz für individuelle Bewertungen oder Notizen zu jedem Creator.',
      },
    },
    insideInfludata: {
      title: 'Die influData-App',
      items: {
        highPerformance: {
          title: 'Leistungsstarke Suchwerkzeuge',
          text:
            'Finde mit unserer Live-Suche in Millisekunden den am besten passenden Creator - auch bei komplexen Kombinationen von Keywords, Geschlecht und Ort.',
        },
        metrics: {
          title: 'Detaillierte "Creator-Metriken" auf einen Blick',
          text:
            'Alles, was du über einen Creator wissen musst, wird auf unserer Profilseite übersichtlich angezeigt. Detaillierte Qualitätsscores, Audience-Reports, Graphen der Followerentwicklung, neueste Beiträge.',
        },
        order: {
          title: 'Ausführliches "Creator-Tracking"',
          text:
            'Wir geben dir einen Überblick über die letzten Storys und Posts und markieren die Mentions und Hashtags deiner Brand. Wir geben dir Statistiken zu deinen Kampagnen und benachrichtigen dich, falls deine Goals erfüllt wurden oder neue Tags gefunden wurden. (Feature bald verfügbar.)',
        },
      },
    },
    keyFeatures: {
      title: 'Dein Next Generation Influencer-Marketing',
      items: [
        { bubbled: '2.5 Mio', title: 'TikTok Creator' },
        { bubbled: '8.5 Mio', title: 'Instagram Creator' },
        { bubbled: '3.5 Mio', title: 'Instagram Brands' },
        {
          bubbled: '# 1',
          title: 'TikTok- und Instagram-Experten in Planung und Entwicklung',
        },
      ],
    },
    testimonials: {
      title: 'Referenzen',
      christianKuper: {
        name: 'Christian Kuper',
        position: 'CEO bei Vincent Vegan',
        text:
          'Für unsere veganen Burger-Restaurants in Hamburg, Berlin und bald auch in Leipzig suchten wir vegetarische oder vegane Influencer. Mit den auf influData gefundenen Creatorn erreichten wir nicht nur Tausende von Followern, sondern haben auch qualitativ hochwertigen und authentischen Content bekommennn.',
      },
      julia: {
        name: 'Julia Schultz-Ering',
        position: 'Digital Marketing Manager bei CatInTheBox',
        text:
          'influData macht es so viel einfacher, Influencer aus verschiedenen Regionen und mit unterschiedlichen Hintergründen, Stilen und Altersgruppen zu finden, zu qualifizieren und zu aktivieren - Die gesamte Zeit, die zuvor in die die Suche investiert wurde, kann jetzt für Management und Strategie verwendet werden.',
      },
      sarahEmmerich: {
        name: 'Sarah Emmerich',
        position: 'Social Media Experte (Selbstständig)',
        text:
          'InfluData erleichtert es mir für meine Kunden, Influencer in verschiedenen Nischen und Größen zu finden. Außerdem ist InfluData mein wichtigstes Tool in der Überprüfung von Influencern, die ich bereits ausgesucht habe, denn ich bekomme eine Qualitäts-Analyse über die Zielgruppe und alle wichtigen Insights, ohne überhaupt mit dem Influencer oder seinem Management in Kontakt getreten zu sein',
      },
    },
    contactUs: {
      title: 'Hola',
      subtitle: 'Wir sind für dich da!',
      text:
        "Bitte schreib' uns dein Anliegen und wir werden uns via Mail bei dir melden. Wir antworten normalerweise innerhalb weniger Stunden.",
    },
    rankings: {
      header: 'Top Rankings der TikTok Influencers & Creators nach Länder',
      description:
        'influData liefert aktuelle Rankings von TikTok Creatorn und sortiert diese nach Follower und Views. Wir stellen auch die meist-verwendeten Hashtags und Songs zur Verfügung und zeigen das Wachstum der letzten 30 Tage..',

      label: 'TikTok-Rangliste',
      byViews: 'nach Views',
      byFollowers: 'nach Followern',
      topHashtags: 'Top Hashtags',
      topSongs: 'Top Songs',
      last30Days: '30 Tage',
      allTime: 'All Time',
      rank: 'Rang',
      account: 'Username',
      name: '',
      growth: 'Wachstum',
      views: 'Views',
      followers: 'Followers',
      seeTop100: 'Siehe Top 100',
      noUsersFound: 'Keine Benutzer gefunden. Bitte ändern Sie die Suchparameter.',
      loadError:
        'Ranglisten konnten nicht geladen werden. Bitte versuchen Sie es später noch einmal.',
      reload: 'Neu laden',
    },
    influencerDB_SEO: 'CEO von InfluencerDB',
    influencerDBData: `influData ist unser Partner für Influencer Discovery und Analyse. Jeder, der auf
      der Suche nach einem Tool für das Finden und Analysieren von Creator-Accounts auf
      Instagram und TikTok ist, ist bei influDatain den besten Händen!`,
    findAnalyzeCreatorAcc: `Finde und analysiere Creator-Accounts mit influData.`,
    saveManageCreatorContacts:
      'Speichere und manage deine Creator-Kontakte & Kampagnen mit InfluencerDB.',
    benefitPartnership:
      'Profitiere jetzt von der InfluencerDB x influData-Partnerschaft!',
    freeInfludataTwoWeeks: `Als InfluencerDB-Lead nutzt du influData zwei Wochen lang kostenlos! Melde dich
    jetzt für deine kostenlose Testphase an, schau dich in Ruhe um und entscheide erst
    dann, ob influData das richtige Tool für dich ist.`,
    firstName: 'Vorname',
    lastName: 'Nachname',
    company: 'Unternehmen',
    phone: 'Telefon',
    companySize: 'Firmengröße',
    selectCompanySize: 'Auswählen',
    position: 'Position',
    selectPosition: 'Auswählen',
    required: 'erforderlich',
    passwordLimit:
      'Das Passwort muss aus mindestens 6 Zeichen bestehen und mindestens einen großen Buchstaben enthalten.',
    finish: 'Abschicken',
    selectCountry: 'Land auswählen',
    password: 'Passwort',
    country: 'Land',
    email: 'E-Mail',
    reenterNotValidEmail: "Email ist ungültig. Bitte gib' die E-Mail-Adresse erneut ein.",
    next: 'Weiter',
    termsOfService: 'Nutzungsbedingungen',
    and: 'und',
    bySigningUpIAgreeTo: 'Mit meiner Anmeldung akzeptiere ich die',
    tellUsAbout: 'Erzähle uns von ',
    yourBusiness: 'deinem Unternehmen ',
    getYour: 'Registriere dich für deine ',
    personalDemo: 'persönliche Demo ',
    andTry: 'und teste ',
    proPlanFor2Weeks: 'unser Pro-Paket unverbindlich für 2 Wochen',
    thankYouForSignUp:
      'Vielen Dank, dass du dich mit uns in Verbindung gesetzt hast. Bitte überprüfe deinen Posteingang und vereinbare eine Demo mit unserem Success Manager.',
    pleaseLogIn: 'Du hast bereits ein Konto, bitte melde dich an',
    tryApp: 'Registrieren und',
    forFree: 'alle Funktionen testen',
    noPayment: 'unverbindlich und ohne Zahlungsdaten',
    userAgree: 'Mit meiner Anmeldung stimme ich zu: influData',
    pageNotFound: 'Diese Seite wurde nicht gefunden',
    goBackHome: 'zur Startseite zurückkehren',
    top: 'INSTAGRAM RANKINGS',
    topInfluencers: {
      topInfluenceTitle:
        'Finden Sie die Top-Influencer auf Instagram nach Land, Stadt oder Kategorie',
      topInfluenceSubtitle: `influData liefert die Top-Influencer aus jeder Nische und aus jeder Stadt der Welt. Diese Influencer sind die jeweils am schnellsten wachsenden Influencer. Auf influData findest du über 12 Millionen Influencer und Brands aus allen Kategorien weltweit.`,
      topInfluenceInputPlaceholder: 'Städte, Länder oder Kategorien eingeben',
      showMore: 'mehr anzeigen',
      byCounty: 'Nach Land',
      byCity: 'Nach Stadt',
      byCategoryAndCity: 'Nach Kategorie und Stadt',
      byCategoryAndCountry: 'Nach Kategorie und Land',
      categoryDetailTopLink: 'Detaillierte Suche auf influData',
      categoryDetailTopTitleFirst: 'Die Top',
      categoryDetailTopTitleSecond: 'Instagram-Influencer',
      categoryDetailTopTitleThird: 'aus',
      categoryDetailTotalInfluencers: 'INFLUENCER (GESAMT)',
      categoryDetailAverageEngagement: 'DURCHSCHNITTLICHES ENGAGEMENT',
      categoryDetailExpectedReach: 'MÖGLICHE IMPRESSIONS',
      categoryDetailFollowersInTotal: 'FOLLOWER INSGESAMT',
      categoryDetailChartTitle: 'Audience aller Influencer',
      categoryDetailTableTitleFirst: 'Die am schnellsten wachsenden Influencer',
      categoryDetailTableTitleSecond: 'in den letzten 30 Tagen (nach Follower)',
      categoryDetailTableGrowthFirst: 'WACHSTUM',
      categoryDetailTableGrowthLast: '(LETZTE 30 TAGE)',
      categoryDetailTableUsername: 'USERNAME',
      categoryDetailTableLocation: 'LOCATION',
      categoryDetailTableLanguage: 'SPRACHE',
      categoryDetailTableName: 'NAME',
      categoryDetailTableBiography: 'BIOGRAPHIE',
      categoryDetailTableFollowers: 'FOLLOWER',
      categoryDetailTableEngagement: 'ENGAGEMENT',
      categoryDetailBottomTitleFirst: 'Finde die Audience von über 10 Millionen',
      categoryDetailBottomTitleSecond: 'Influencern und finde die passendsten',
      categoryDetailBottomTitleLasts: 'Influencer für deine Brand',
      categoryDetailBottomButton: 'STARTE DEINEN TRIAL HEUTE',
      categoryDetailBottomAfterButtonText: 'und nutze alle Funktionen',
      chartCountry: 'Land',
      chartCity: 'Stadt',
      chartLanguage: 'Sprache',
      chartAgeGroups: 'Altersgruppen',
      chartGender: 'Geschlecht',
      pieChartAttrFemale: 'Weiblich',
      pieChartAttrMale: 'Männlich',
    },
  },
}
