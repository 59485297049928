import availableLangs from './availableLangs'

export default {
  [availableLangs.en]: {
    customLikeYourBrand: 'custom, like your brand',
    yourInfluencerMarketingSuperpowers: 'Your influencer marketing superpowers.',
    plan: 'plan',
    pricing: 'pricing',
    getAQuote: 'get a quote',
    plans: {
      cloud: {
        name: 'influData Cloud',
        description:
          'influData Cloud is the foundation of modern influencer marketing and always includes unlimited basic search and quality scores as well as brand analytics for millions of influencers. You can extend it with all the sophisticated addons, up to unlimited audience reports and logins as well as individual billing intervalls or even API access.',
        pricing:
          'Your influData plan is as individual as the influencers you search for, therefore we will create a custom quote for you, based on your needs. We give smaller brands and agencies the flexibility to pay only for the features that are needed. This keeps it simple and flexible. We will provide you with a trial period that includes most of our features, so you can decide what you want to include.',
      },
    },
    features: [
      {
        name: 'Find',
        items: [
          {
            name: 'Instagram Influencers',
            icon: 'instagramInfluencers',
            description:
              'Find up to 10 Million Instagram Influencers worldwide with up-to-date metrics and statistics. Add influencers within seconds in the rare event that they are not in our database.',
          },
          {
            name: 'TikTok Creators',
            icon: 'tikTokCreators',
            description:
              'Find up to 5 Million TikTok Creators worldwide and add new creators in seconds.',
          },
          {
            name: 'Brands',
            icon: 'brands',
            description:
              'Our AI algorithms can separate brands from influencers. This gives you the opportunity to gain insights into the most successful brand accounts.',
          },
          {
            name: 'Micro- and Macro Influencers',
            icon: 'microMacroInfluencers',
            description:
              'We find accounts from 3000 followers and up. This allows you to launch campaigns in the micro influencer segment.',
          },
        ],
      },
      {
        name: 'Search Engine',
        items: [
          {
            name: 'Search Requests',
            icon: 'searchRequests',
            description:
              'We don’t limit our search. That way, we give you the freedom to search the influencer market as long as you want without any limitations.',
          },
          {
            name: 'Results',
            icon: 'results',
            description:
              'Each search provides you with thousands of influencers, based on your search criteria. Our smart sorting algorithm displays the influencer that might be most useful for your campaign first.',
          },
          {
            name: 'Search by Country, City, Followers, Keywords, Gender',
            icon: 'searchByCountryCity',
            description:
              'Every brand is different, and so are influencers. We give you the freedom to manipulate the search parameters in any way you’d like and provide you with a free text search that detects any information in the bio or even in the post captions.',
          },
          {
            name: 'Search by Growth',
            icon: 'searchByGrowth',
            description:
              'Only find top growing influencers. You can filter out influencers that have a small growth rate or even lose followers and get the rising stars in your niche.',
          },
          {
            name: 'Specific Search by Audience',
            icon: 'searchByAudience',
            description:
              'Don’t search for the influencer’s metrics alone. You can find influencers that have audiences from different countries, and you can even add a minimum percentage. The same goes for the gender distribution and the age groups of the followers.',
          },
          {
            name: 'Search by Views per Post or Plays per Reel',
            icon: 'searchByViewsPlays',
            description:
              'You can not only use the rather unreliable follower count to determine the size of the influencer, but rather search for the actual average views of each post or the median plays per reel. ',
          },
        ],
      },
      {
        name: 'Scoring & Benchmarking',
        items: [
          {
            name: 'Profile Statistics',
            icon: 'profileStatistics',
            description:
              'We provide the most important statistics, such as engagement, growth rate, media plays per post, views per story or even average plays per reel.<br /> <br />We also provide email addresses for most influencers, so you can contact them via email.<br />In addition, we provide a graph of the follower count history over the last 3 years for many influencers.',
          },
          {
            name: 'Quality Analysis and Benchmarks',
            icon: 'qualityAnalysisBenchmarks',
            description:
              'The scoring is our main feature, and we have helped many brands detect bad influencers and avoid unsuccessful campaigns.<br /> We compare influencers to similar influencers based on their frequency of posts, stories and reels.<br />We compare and evaluate the engagement of posts based on likes and comments and the growth rate to similar influencers.<br />We compare and evaluate the plays per reel, the average watch time per reel, and the engagement of reels to similar influencers.',
          },
          {
            name: 'Brands & Sponsored Posts',
            icon: 'brandsSponsoredPosts',
            description:
              'Get the latest insights on the collaboration with brands and check the brands that the influencer has collaborated with. Check what percentage of their posts, stories and reels include a mention of a brand.',
          },
          {
            name: 'Price per Content (CPMs or cost per mille)',
            icon: 'pricePerContent',
            description:
              'Know the price - Based on the influencer’s scores, statistics, audience and even their niche, we estimate the costs for stories, posts and reels and give you the value per 1000 impressions, but also for an average content piece.',
          },
        ],
      },
      {
        name: 'Audience Insights',
        items: [
          {
            name: 'Location by Country',
            icon: 'locationByCountry',
            description:
              'We provide you with the location of the audience and give you detailed information about what percentage and what absolute numbers of the audience is based in which countries. You will find the top 10 countries the influencer has an audience in.',
          },
          {
            name: 'Location by City',
            icon: 'locationByCity',
            description:
              'We go into even more detail and provide you with the top 10 cities the influencer has an audience in.',
          },
          {
            name: 'Language',
            icon: 'language',
            description:
              'Make sure the audience speaks your language - we display the percentage and absolute numbers of followers that use a given language in their profiles and when they write comments.',
          },
          {
            name: 'Gender',
            icon: 'gender',
            description:
              'What percentage of the audience is male and what part is female? Make sure that the audience of any given influencer is a part of your target group.',
          },
          {
            name: 'Audience Type',
            icon: 'audienceType',
            description:
              'As you might know, not all followers are real and engaging. We check each follower, if he or she is active, engaging and natural or if this is a follower that follows thousands of other influencers, if the follower is an influencer themselves or if this is a completely inactive profile.  ',
          },
          {
            name: 'Age Groups and Average Age',
            icon: 'ageGroupsAvgAge',
            description:
              'We provide you with an age distribution of the influencer’s audience and give you their follower’s median age.',
          },
          {
            name: 'Average Audience of a group of influencers',
            icon: 'influencersGroupAvgAudience',
            description:
              'We not only provide you with the audience of any individual influencer, we also give you the accumulated audience and reach of all users in one collection. This way you can plan your overall reach and target group for your campaigns without comparing each influencer individually',
          },
          {
            name: 'Audience Overlap',
            icon: 'audienceOverlap',
            description: 'What part of the followers between two or more influencers are the same? We give you several ways to check the overlap between two individual influencers and even between all influencers in a collection.',
          },
        ],
      },
      {
        name: 'Collect & Teamwork',
        items: [
          {
            name: 'Team Members',
            icon: 'teamMembers',
            description:
              'Add your colleagues or members of your team and work together on influencer research and collecting the right influencers for the campaigns.',
          },
          {
            name: 'Favorites & Collections',
            icon: 'favoritesCollections',
            description:
              'Use your collections or your favorites to store the influencers you want to work with or might just keep in mind.<br />Additionally, any member can create private and public collections and can decide who can view and edit each collection.',
          },
          {
            name: 'Custom Fields',
            icon: 'customFields',
            description:
              'Add as many custom fields to all your influencers and collect individual and private information, like dates, yes/no fields or multi-select fields. ',
          },
          {
            name: 'Comments',
            icon: 'comments',
            description:
              'All of your team members can discuss and work together on influData',
          },
        ],
      },
      {
        name: 'Exports & API',
        items: [
          {
            name: 'PDF Exports',
            icon: 'pdfExports',
            description:
              'influData offers the possibility to export each profile as PDF with all statistics and metrics that you also find within the profile page. We also offer a white-label option to customize the reports and include or exclude any information you might need for the report.',
          },
          {
            name: 'CSV- /Excel Exports',
            icon: 'csvExports',
            description:
              'Export collections and campaigns in CSV format or as an Excel file and extract all data you need.',
          },
          {
            name: 'API',
            icon: 'api',
            description:
              'Optional: We offer access to our API to extract our data to your individual interface. Please contact us, if you need more information.',
          },
        ],
      },
      {
        name: 'Support',
        items: [
          {
            name: '24/7 Chat Support<br />Phone Support<br />Mail Support',
            icon: 'support',
            description:
              'You can reach us any time via phone, chat or email and we are happy to support you in multiple languages.',
          },
        ],
      },
      {
        name: 'Payment',
        items: [
          {
            name: 'Invoice<br />PayPal<br />Credit Card<br />Direct Debit',
            icon: 'payment',
            description:
              'We offer a wide range of different payment methods and intervals to fulfill all your individual needs.',
          },
        ],
      },
    ],
  },
  [availableLangs.de]: {
    customLikeYourBrand: 'custom, like your brand',
    yourInfluencerMarketingSuperpowers: 'Deine Influencer-Marketing Superpower.',
    plan: 'paket',
    pricing: 'pricing',
    getAQuote: 'Angebot einholen',
    plans: {
      cloud: {
        name: 'influData Cloud',
        description:
          'influData Cloud ist die Grundlage des modernen Influencer-Marketings und beinhaltet unlimitierte Such- und Qualitäts-Scores und Brand-Fit-Analysen für Millionen von Influencern. Erweiterbar mit allen möglichen Addons, bis hin zu unbegrenzten Audience Reports und Logins, sowie individuellen Zahlungsintervallen oder API-Zugang.',
        pricing:
          'Dein influData-Paket ist so individuell wie die Influencer, nach denen du suchst. Wir erstellen daher ein individuelles Angebot für dich, das auf deine Bedürfnisse zugeschnitten ist. Wir geben Unternehmen, die nur gelegentlich Influencer-Marketing betreiben, die Möglichkeit, nur die Funktionen zu buchen, die tatsächlich genutzt werden. Damit halten wir die Pakete einfach und liefern eine größtmögliche Flexibilität. Du bekommst immer einen komplett unverbindlichen Testzeitraum und ein ausführliches persönliches Onboarding, um eine möglichst hohe Trasparenz vor dem dem Kauf sicherzustellen.',
      },
    },
    features: [
      {
        name: 'Finden',
        items: [
          {
            name: 'Instagram-Influencer',
            icon: 'instagramInfluencers',
            description:
              'Finde bis zu 10 Millionen Instagram-Influencer weltweit mit aktuellen Metriken und Statistiken. Füge Influencer innerhalb von Sekunden hinzu, falls sie noch nicht in unserer Datenbank sind.',
          },
          {
            name: 'TikTok-Creator',
            icon: 'tikTokCreators',
            description:
              'Finde bis zu 5 Millionen TikToker weltweit oder füge in Sekundenschnelle neue hinzu.',
          },
          {
            name: 'Brands & Unternehmen',
            icon: 'brands',
            description:
              'Unsere Algorithmen können Marken von Influencern unterscheiden. Du kannst so Erkenntnisse über die erfolgreichsten Marken-Accounts gewinnen.',
          },
          {
            name: 'Micro- und Macro-Influencer',
            icon: 'microMacroInfluencers',
            description:
              'Wir finden Accounts ab 3000 Followern für Kampagnen im Mikro-Influencer-Segment.',
          },
        ],
      },
      {
        name: 'Search Engine',
        items: [
          {
            name: 'Suchanfragen',
            icon: 'searchRequests',
            description:
              'Wir begrenzen unsere Suche nicht. So geben wir dir die Freiheit, den Influencer-Markt so lange zu durchsuchen, wie du willst, ganz ohne Einschränkungen.',
          },
          {
            name: 'Ergebnisse',
            icon: 'results',
            description:
              'Jede Suche liefert dir Tausende von Influencern, basierend auf deinen Suchkriterien. Unser intelligenter Sortier-Algorithmus zeigt zuerst die Influencer an, die für deine Kampagne am geeignetsten sein könnten.',
          },
          {
            name: 'Suche nach Land, Stadt, Followerzahl, Keyword und Geschlecht',
            icon: 'searchByCountryCity',
            description:
              'Jede Marke ist einzigartig und das gilt auch für Influencer. Wir geben dir die Freiheit, die Suchparameter nach Belieben zu verändern, und stellen dir eine freie Textsuche zur Verfügung, die alle Informationen in der Bio und sogar in den Posts erkennt.',
          },
          {
            name: 'Suche nach Wachstum',
            icon: 'searchByGrowth',
            description:
              'Finde nur die am schnellsten wachsenden Influencer. Du kannst Influencer herausfiltern, die eine geringe Wachstumsrate haben oder sogar Follower verlieren, und dir die Nachwuchsstars in deiner Nische sichern.',
          },
          {
            name: 'Suche nach Audience-Kriterien',
            icon: 'searchByAudience',
            description:
              'Suche nicht nur nach den Metriken des Influencers. Du kannst Influencer finden, die ein Publikum in verschiedenen Ländern haben, und du kannst sogar einen Mindestprozentsatz festlegen. Das Gleiche gilt für die Geschlechterverteilung oder die Altersgruppen der Follower.',
          },
          {
            name: 'Suche nach Plays bzw. Views pro Post oder Reel',
            icon: 'searchByViewsPlays',
            description:
              'Du musst dich nicht nur auf die eher unzuverlässige Followerzahl stützen, um die Größe des Influencers zu bestimmen, sondern kannst auch nach den tatsächlichen durchschnittlichen Aufrufen der einzelnen Posts oder den Aufrufe der Reels suchen.',
          },
        ],
      },
      {
        name: 'Scoring & Benchmarking',
        items: [
          {
            name: 'Profil-Statistiken',
            icon: 'profileStatistics',
            description:
              'Wir liefern die wichtigsten Statistiken, wie Engagement, Wachstumsrate, Medienaufrufe pro Post, Aufrufe pro Story und sogar die durchschnittlichen Aufrufe pro Reel.<br />Wir stellen auch die E-Mail-Adressen der meisten Influencer bereit, damit du sie per E-Mail kontaktieren kannst.<br />Darüber hinaus liefern wir für viele Influencer den Verlauf der Followerzahlen in den letzten 3 Jahre.',
          },
          {
            name: 'Qualitäts-Analyse und Benchmarks',
            icon: 'qualityAnalysisBenchmarks',
            description:
              'Unsere wichtigste Funktion ist das Scoring. Wir haben schon vielen Marken geholfen, schlechte Influencer zu erkennen und erfolglose Kampagnen zu vermeiden.<br />Wir vergleichen Influencer anhand der Häufigkeit von Posts, Stories und Reels mit ähnlichen Influencern.<br />Wir vergleichen und bewerten die Wachstumsrate sowie das Engagement der Beiträge anhand von Likes und Kommentaren mit ähnlichen Influencern.<br />Wir vergleichen und bewerten die Aufrufe pro Reel, die durchschnittliche Verweildauer pro Reel und das Engagement der Reels mit ähnlichen Influencern.',
          },
          {
            name: 'Brands & Sponsored-Posts',
            icon: 'brandsSponsoredPosts',
            description:
              'Hol dir Informationen über die Zusammenarbeit mit Marken und sieh dir die Unternehmen an, mit denen der Influencer zusammengearbeitet hat. Schau nach, wie viel Prozent der Posts, Stories und Reels eine Marke erwähnen.',
          },
          {
            name: 'Preis pro Beitrag (TKP oder Tausenderkontaktpreis)',
            icon: 'pricePerContent',
            description:
              'Erfahre die Preise – basierend auf den Bewertungen, den Statistiken, dem Zielpublikum und sogar der spezifischen Nische des Influencers schätzen wir die Kosten für Stories, Posts und Reels und nennen dir die Kosten pro 1000 Impressionen sowie auf einen durchschnittlichen Beitrag gerechnet.',
          },
        ],
      },
      {
        name: 'Audience Insights',
        items: [
          {
            name: 'Herkunft nach Land',
            icon: 'locationByCountry',
            description:
              'Wir zeigen dir den Standort des Publikums und stellen dir detaillierte Informationen darüber zur Verfügung, welcher Anteil des Publikums sich in welchem Land befindet – sowohl in Prozent als auch in absoluten Zahlen.<br />So findest du die 10 wichtigsten Länder, in denen der Influencer ein Publikum hat.',
          },
          {
            name: 'Herkunft nach Stadt',
            icon: 'locationByCity',
            description:
              'Wir gehen sogar noch weiter ins Detail und geben dir die Top 10 Städte an, in denen der Influencer ein Publikum hat.',
          },
          {
            name: 'Sprache',
            icon: 'language',
            description:
              'Stelle sicher, dass dein Publikum deine Sprache spricht - wir zeigen die prozentuale und absolute Anzahl der Follower an, die in ihren Profilen und beim Schreiben von Kommentaren eine bestimmte Sprache verwenden.',
          },
          {
            name: 'Geschlecht',
            icon: 'gender',
            description:
              'Welcher Anteil des Publikums ist männlich und welcher weiblich? Gehe sicher, dass das Publikum eines bestimmten Influencers zu deiner Zielgruppe gehört.',
          },
          {
            name: 'Audience-Typ',
            icon: 'audienceType',
            description:
              'Wie du vielleicht weißt, sind nicht alle Follower echt und sorgen somit auch nicht für Engagement.<br />Wir überprüfen jeden Follower, um festzustellen, ob dieser aktiv ist, mit Beiträgen interagiert oder ob es sich um einen Follower handelt, der Tausenden von anderen Influencern folgt, ob der Follower selbst ein Influencer ist oder ob es sich um ein völlig inaktives Profil handelt.',
          },
          {
            name: 'Altersgruppen',
            icon: 'ageGroupsAvgAge',
            description:
              'Wir stellen dir eine Altersstruktur des Publikums sowie das Durchschnittsallter der Follower zur Verfügung.',
          },
          {
            name: 'Audience einer Gruppe von Influencern',
            icon: 'influencersGroupAvgAudience',
            description:
              'Wir zeigen dir nicht nur die Audience eines einzelnen Influencers, sondern geben dir auch die gesammelten Statistiken von allen Influencern innerhalb einer Collection.<br />Somit kannst du die Zielgruppe deiner Kampagne planen, ohne jeden einzelnen Influencer händisch zu vergleichen.',
          },
          {
            name: 'Audience-Überschneidung',
            icon: 'audienceOverlap',
            description: 'Welcher Teil der Follower von zwei oder mehr Influencers sind gleich? Wir geben dir mehrere Statistiken, die Überschneidung zwischen einzelnen Influencern und auch von allen Influencern innerhalb einer Collection zu überprüfen. ',
          },
        ],
      },
      {
        name: 'Collect & Teamwork',
        items: [
          {
            name: 'influData im Team',
            icon: 'teamMembers',
            description:
              'Füge deine Kollegen oder Mitglieder deines Teams hinzu und arbeite gemeinsam an der Influencer-Recherche und dem Zusammentragen der richtigen Influencer für die Kampagnen',
          },
          {
            name: 'Favoriten & Collections',
            icon: 'favoritesCollections',
            description:
              'Verwende deine Sammlungen oder deine Favoriten, um die Influencer zu speichern, mit denen du zusammenarbeiten oder die du im Hinterkopf behalten möchtest.<br />Außerdem kann jedes Mitglied private und öffentliche Sammlungen anlegen und festlegen, wer die einzelnen Sammlungen einsehen und bearbeiten darf.',
          },
          {
            name: 'Benutzerdefinierte Felder',
            icon: 'customFields',
            description:
              'Füge all deinen Influencern beliebig viele benutzerdefinierte Felder hinzu und sammle individuelle und persönliche Informationen, wie z.B. Daten, Ja/Nein-Felder oder Mehrfachauswahlfelder.',
          },
          {
            name: 'Kommentare',
            icon: 'comments',
            description:
              'Alle deine Teammitglieder können gemeinsam mit influData arbeiten und diskutieren.',
          },
        ],
      },
      {
        name: 'Exporte & API',
        items: [
          {
            name: 'PDF-Exporte',
            icon: 'pdfExports',
            description:
              'influData bietet dir die Möglichkeit, jedes Profil als PDF zu exportieren, und zwar mit allen Statistiken und Metriken, die du auch auf der Profilseite findest.<br />Wir bieten zudem eine White-Label-Option an, mit der du die Berichte individuell anpassen und die Informationen, die für den Bericht benötigt oder nicht benötigt werden, einbeziehen bzw. entfernen kannst.',
          },
          {
            name: 'CSV- /Excel-Exporte',
            icon: 'csvExports',
            description:
              'Exportiere Collections und Kampagnen im CSV-Format oder als Excel-Datei und extrahiere alle Daten, die du brauchst.',
          },
          {
            name: 'API',
            icon: 'api',
            description:
              'Optional: Wir bieten dir Zugriff auf unsere API, sodass du unsere Daten auf dein individuelles Interface übertragen kannst. Bitte kontaktiere uns, falls du mehr Informationen benötigst.',
          },
        ],
      },
      {
        name: 'Support',
        items: [
          {
            name: '24/7 Chat-Support<br />Telefon-Support<br />E-Mail-Support',
            icon: 'support',
            description:
              'Du kannst uns jederzeit per Telefon, Chat oder E-Mail erreichen und wir unterstützen dich gerne in mehreren Sprachen.',
          },
        ],
      },
      {
        name: 'Zahlung',
        items: [
          {
            name: 'Rechnung<br />PayPal<br />Kreditkarte<br />Lastschrift',
            icon: 'payment',
            description:
              'Wir bieten eine große Anzahl verschiedener Zahlungsmethoden und -intervalle an, um deinen individuellen Anforderungen gerecht zu werden.',
          },
        ],
      },
    ],
  },
}
