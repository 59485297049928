import { useContext } from 'react'
import { LocalizationContext } from '@providers/LocalizationProvider'

const useTranslation = () => {
  const context = useContext(LocalizationContext)

  return { ...context }
}

export default useTranslation
