import LocalizedStrings from 'react-localization'
import availableLangs from './availableLangs'
import { localizedCountries } from './localizedCountries'
import localizedLabelStrings from './localizedLabelStrings'
import localizedLanguages from './localizedLanguages'
import localizedPricingPlans from './localizedPricingPlans'
import countriesFlags from './countriesFlags'

export const localizations = new LocalizedStrings({
  [availableLangs.en]: {
    labelStrings: localizedLabelStrings.en,
    pricingPlansStrings: localizedPricingPlans.en,
    languages: localizedLanguages.en,
    flags: countriesFlags.en,
    countries: localizedCountries.en,
  },
  [availableLangs.de]: {
    labelStrings: localizedLabelStrings.de,
    pricingPlansStrings: localizedPricingPlans.de,
    languages: localizedLanguages.de,
    flags: countriesFlags.en,
    countries: localizedCountries.de,
  },
})

export const languageOptions = lang => [
  { value: '', label: localizedLanguages[lang].all },
  { value: 'de', label: localizedLanguages[lang].german },
  { value: 'en', label: localizedLanguages[lang].english },
  { value: 'es', label: localizedLanguages[lang].spanish },
  { value: 'fr', label: localizedLanguages[lang].french },
  { value: 'pt', label: localizedLanguages[lang].portuguese },
  { value: 'it', label: localizedLanguages[lang].italian },
  { value: 'da', label: localizedLanguages[lang].danish },
  { value: 'ru', label: localizedLanguages[lang].russian },
  { value: 'lv', label: localizedLanguages[lang].latvian },
  { value: 'cz', label: localizedLanguages[lang].czech },
  { value: 'pl', label: localizedLanguages[lang].polish },
  { value: 'ar', label: localizedLanguages[lang].arabic },
  { value: 'bg', label: localizedLanguages[lang].bulgarian },
  { value: 'nl', label: localizedLanguages[lang].dutch },
  { value: 'fi', label: localizedLanguages[lang].finnish },
  { value: 'ja', label: localizedLanguages[lang].japanese },
  { value: 'hu', label: localizedLanguages[lang].hungarian },
  { value: 'no', label: localizedLanguages[lang].norwegian },
  { value: 'el', label: localizedLanguages[lang].greek },
  { value: 'ro', label: localizedLanguages[lang].romanian },
  { value: 'tr', label: localizedLanguages[lang].turkish },
  { value: 'li', label: localizedLanguages[lang].ligurian },
  { value: 'cn', label: localizedLanguages[lang].chinese },
  { value: 'zh', label: localizedLanguages[lang].chinese },
]

export const countryOptions = lang => [
  { value: '', label: localizedCountries[lang].all },
  { value: 'Germany', label: localizedCountries[lang].germany },
  { value: 'Austria', label: localizedCountries[lang].austria },
  { value: 'Switzerland', label: localizedCountries[lang].switzerland },
  { value: 'Italy', label: localizedCountries[lang].italy },
  { value: 'Spain', label: localizedCountries[lang].spain },
  { value: 'United Kingdom', label: localizedCountries[lang].unitedKingdom },
  { value: 'United States of America', label: localizedCountries[lang].usa },
  { value: 'Albania', label: localizedCountries[lang].albania },
  { value: 'Algeria', label: localizedCountries[lang].algeria },
  { value: 'Argentina', label: localizedCountries[lang].argentina },
  { value: 'Armenia', label: localizedCountries[lang].armenia },
  { value: 'Australia', label: localizedCountries[lang].australia },
  { value: 'Belarus', label: localizedCountries[lang].belarus },
  { value: 'Belgium', label: localizedCountries[lang].belgium },
  { value: 'Bolivia', label: localizedCountries[lang].bolivia },
  { value: 'Bosnia and Herzegovina', label: localizedCountries[lang].bosniaHerzegovina },
  { value: 'Brazil', label: localizedCountries[lang].brazil },
  { value: 'Bulgaria', label: localizedCountries[lang].bulgaria },
  { value: 'Canada', label: localizedCountries[lang].canada },
  { value: 'Chile', label: localizedCountries[lang].chile },
  { value: 'China', label: localizedCountries[lang].china },
  { value: 'Colombia', label: localizedCountries[lang].colombia },
  { value: 'Costa Rica', label: localizedCountries[lang].costaRica },
  { value: 'Croatia', label: localizedCountries[lang].croatia },
  { value: 'Cuba', label: localizedCountries[lang].cuba },
  { value: 'Cyprus', label: localizedCountries[lang].cyprus },
  { value: 'Czechia', label: localizedCountries[lang].czechia },
  { value: 'Denmark', label: localizedCountries[lang].denmark },
  { value: 'Dominican Republic', label: localizedCountries[lang].dominicanRepublic },
  { value: 'Ecuador', label: localizedCountries[lang].ecuador },
  { value: 'Egypt', label: localizedCountries[lang].egypt },
  { value: 'Estonia', label: localizedCountries[lang].estonia },
  { value: 'Finland', label: localizedCountries[lang].finland },
  { value: 'France', label: localizedCountries[lang].france },
  { value: 'Greece', label: localizedCountries[lang].greece },
  { value: 'Guatemala', label: localizedCountries[lang].guatemala },
  { value: 'Honduras', label: localizedCountries[lang].honduras },
  { value: 'Hong Kong', label: localizedCountries[lang].hongkong },
  { value: 'Hungary', label: localizedCountries[lang].hungary },
  { value: 'Iceland', label: localizedCountries[lang].iceland },
  { value: 'India', label: localizedCountries[lang].india },
  { value: 'Indonesia', label: localizedCountries[lang].indonesia },
  { value: 'Iran', label: localizedCountries[lang].iran },
  { value: 'Ireland', label: localizedCountries[lang].ireland },
  { value: 'Israel', label: localizedCountries[lang].israel },
  { value: 'Jamaica', label: localizedCountries[lang].jamaica },
  { value: 'Japan', label: localizedCountries[lang].japan },
  { value: 'Libya', label: localizedCountries[lang].libya },
  { value: 'Lithuania', label: localizedCountries[lang].lithuania },
  { value: 'Luxembourg', label: localizedCountries[lang].luxembourg },
  { value: 'Macedonia', label: localizedCountries[lang].macedonia },
  { value: 'Mexico', label: localizedCountries[lang].mexico },
  { value: 'Morocco', label: localizedCountries[lang].morocco },
  { value: 'Netherlands', label: localizedCountries[lang].netherlands },
  { value: 'New Zealand', label: localizedCountries[lang].newZealand },
  { value: 'Nicaragua', label: localizedCountries[lang].nicaragua },
  { value: 'Norway', label: localizedCountries[lang].norway },
  { value: 'Panama', label: localizedCountries[lang].panama },
  { value: 'Paraguay', label: localizedCountries[lang].paraguay },
  { value: 'Peru', label: localizedCountries[lang].peru },
  { value: 'Philippines', label: localizedCountries[lang].philippines },
  { value: 'Poland', label: localizedCountries[lang].poland },
  { value: 'Portugal', label: localizedCountries[lang].portugal },
  { value: 'Romania', label: localizedCountries[lang].romania },
  { value: 'Russia', label: localizedCountries[lang].russia },
  { value: 'Saudi Arabia', label: localizedCountries[lang].saudiArabia },
  { value: 'Serbia', label: localizedCountries[lang].serbia },
  { value: 'Singapore', label: localizedCountries[lang].singapore },
  { value: 'Slovakia', label: localizedCountries[lang].slovakia },
  { value: 'Slovenia', label: localizedCountries[lang].slovenia },
  { value: 'South Africa', label: localizedCountries[lang].southAfrica },
  { value: 'South Korea', label: localizedCountries[lang].southKorea },
  { value: 'Sweden', label: localizedCountries[lang].sweden },
  { value: 'Taiwan', label: localizedCountries[lang].taiwan },
  { value: 'Thailand', label: localizedCountries[lang].thailand },
  { value: 'Tunisia', label: localizedCountries[lang].tunisia },
  { value: 'Turkey', label: localizedCountries[lang].turkey },
  { value: 'Ukraine', label: localizedCountries[lang].ukraine },
  { value: 'Uruguay', label: localizedCountries[lang].uruguay },
]

export default {
  localizations,
  countryOptions,
  languageOptions,
}
